const obj = {
  title: 'Email Maker ',
  desc: 'Turn small details into a professionl email.',
  category: 'Content',
  // tags: [],
  permissions: ['user'],

  to: '/ai/emailmaker',
  api: '/ai/emailmaker',

  output: {
    title: 'Completed',
    desc: 'Email output.',
    Icon: false,
  },

  prompts: [
    {
      title: 'Email input',
      desc: 'Input small details about the email.',
      // n: 1,
      prompts: [
        {
          title: 'Content',
          attr: 'content',
          value: '',
          placeholder: 'Write an email to...',
          label: '',
          type: 'textarea',
          maxLength: 600,
          // max: 100,
          min: 3,
          required: true,
          error: '',
          example: 'Beta',
        },
      ],
      example: {
        output: 'Beta no output.',
      },
    },
  ],
};

export default obj;
