import { Link } from 'react-router-dom';
import plans from '../data/plans';
import plansCredits from '../data/plansCredits';
import DatabaseIcon from '../media/DatabaseIcon';
import FilledDatabaseIcon from '../media/FilledDatabaseIcon';

import formatNumber from '../utils/formatNumber';

export const Plan = ({ planName, baseURL, api, href }) => {
  const { fromColor, toColor, textColor, shadowColor, ...plan } =
    plans[planName];
  const planCredits = plansCredits[planName];

  const btn = (
    <button
      type="submit"
      className={`mt-8 inset-0 bg-gradient-to-r ${fromColor} ${toColor} shadow-lg flex-1 rounded-xl p-4 text-gray-800 font-medium text-center text-lg transition hover:from-gray-700 hover:to-gray-800 text-enter`}
    >
      {plan.isFreeTrial ? ' Try Out' : 'Get Started'}
    </button>
  );

  return (
    <div className="flex relative">
      <div
        style={{ boxShadow: `0 1px 10px 0 ${shadowColor}, 0 1px 2px -1px ` }}
        className={`shadow-md shadow-indigo-500/40 bg-gray-800 rounded-2xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-blue-500 border-t-2 border- md:flex relative transform hover:scale-105  hover:text-blue-500 flex-1`}
      >
        <div className="p-8 flex-1">
          <div
            className={`${textColor} block text-2xl leading-tight font-medium mb-2`}
          >
            {plan.title}
          </div>
          <div className="text-gray-500 font-bold">
            <span style={{ fontSize: 28 }}>${plan.price}</span>
            <span
              className="text-lg text-blue-400"
              style={{ display: 'inline-flex' }}
            >
              {plan.isFreeTrial ? ' free trial' : '/per month'}
            </span>
          </div>
          <p className="mt-4 text-lg">{plan.description}</p>
          <div className=" mt-4">
            <div className="py-2 flex items-center mb-2">
              <FilledDatabaseIcon className={`w-6 h-6 mr-2 ${textColor}`} />
              <div>
                <span className="font-medium text-purple-400">
                  {formatNumber(planCredits)}
                </span>
                {` x `}Credits
              </div>
            </div>

            <div>{plan.detailsText}</div>
          </div>

          {href ? (
            <Link className="flex" to={href}>
              {btn}
            </Link>
          ) : (
            <form
              action={baseURL + 'user/stripe/subscribe'}
              method="POST"
              className="flex flex-1"
            >
              <input
                type="hidden"
                name="token"
                value={api.defaults.headers.common['x-access-token']}
              />
              <input type="hidden" name="priceId" value={plan.priceId} />
              {btn}
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Plan;
