const chatStandard = {
  title: 'Python Chat',
  desc: 'Ask me questions about python coding.',
  category: 'Chat',

  permissions: ['user'],

  to: '/chat/python',
  api: '/chat-ai/python',
};

export default chatStandard;
