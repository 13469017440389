const imageStandardUpscale = {
  type: 'image/upscale',

  title: 'Image Upscale',
  desc: 'upscale the given image',
  category: 'Image',

  permissions: ['user'],

  to: '/ai/image/upscale/standard',
  api: '/image-ai/upscale/standard',

  formatPrompt: (prompt) => `${prompt}`,

  output: {
    title: 'Completed',
    desc: 'Output.',
    Icon: false,
  },

  prompts: [
    {
      title: 'Image Upscale',
      desc: 'Can take up to 10+ seconds.',
      prompts: [
        {
          type: 'file',
          title: 'Image',
          attr: 'originalImage',
          value: null,
          label: '',
          required: true,
          error: '',
          example: '/images/image-upscale-input.png',
          fileAccept: 'image/png',
        },
        {
          type: 'select',
          title: 'Scale Dimension',
          attr: 'scaleDimension',
          value: 'width',
          example: 'width',
          selectOptions: [
            {
              label: 'Width x Height',
              value: 'width',
            },
            {
             // label: 'Height',
              value: 'height',
            },
          ],
          label: '',
          required: true,
          error: '',
        },
        {
          type: 'select',
          title: 'Size',
          attr: 'size',
          value: 1024,
          example: 1024,
          selectOptions: [
            {
              label: '512',
              value: 512,
            },
            {
              label: '768',
              value: 768,
            },
            {
              label: '1024',
              value: 1024
            },
            {
              label: '2048',
              value: 2048
            },
          ],
          label: '',
          required: true,
          error: '',
        },
      ],
      example: {
        outputImages: ['/images/image-upscale-output.png'],
      },
    },
  ],
};

export default imageStandardUpscale;
