const obj = {
  title: 'Instant Resume',
  desc: 'Create professional resumes from small bits of information about yourself.',
  category: '',
  // tags: [],
  permissions: ['user'],

  to: '/ai/resume',
  api: '/ai/resume',

  output: {
    title: 'Output',
    desc: 'The following resume has been made',
    Icon: false,
  },

  prompts: [
    {
      title: 'Entry Text',
      desc: 'Input details about yourself Name, Education, Work expericnce, Awards, Etc...',
      // n: 1,
      prompts: [
        {
          title: 'Content',
          attr: 'content',
          value: '',
          placeholder: 'Enter text here',
          label: '',
          type: 'textarea',
          maxLength: 1200,
          // max: 100,
          min: 3,
          required: true,
          error: '',
          example: 'Beta Mode',
        },
      ],
      example: {
        output: 'No examples Beta mode',
      },
    },
  ],
};

export default obj;
