const obj = {
  title: 'Social Media Ads',
  desc: 'Turn small details into a professionl social media ad.',
  category: 'Socialmedia',
  // tags: [],
  permissions: ['user'],

  to: '/ai/social',
  api: '/ai/social',

  output: {
    title: 'Completed',
    desc: 'Ad output.',
    Icon: false,
  },

  prompts: [
    {
      title: 'Ad input',
      desc: 'Input small details about the ad.',
      // n: 1,
      prompts: [
        {
          title: 'Content',
          attr: 'content',
          value: '',
          placeholder: 'Make an ad about...',
          label: '',
          type: 'textarea',
          maxLength: 600,
          // max: 100,
          min: 3,
          required: true,
          error: '',
          example: 'Air show next saturday the 10th at 8am.',
        },
      ],
      example: {
        output:
          "Get ready to witness the greatest air show of all time! Come and see the amazing aerobatics and stunts that will leave you breathless. Don't forget to bring your camera. #Airshow #Aerobatics #Stunts #SaturdayFun.",
      },
    },
  ],
};

export default obj;
