const obj = {
  title: 'Dating profile',
  desc: 'Make a dating profile from details about yourself.',
  category: '',
  // tags: [],
  permissions: ['user'],

  to: '/ai/date',
  api: '/ai/date',

  output: {
    title: 'Output',
    desc: 'Finished Profile.',
    Icon: false,
  },

  prompts: [
    {
      title: 'Entry Text',
      desc: 'Input details.',
      // n: 1,
      prompts: [
        {
          title: 'Content',
          attr: 'content',
          value: '',
          placeholder: 'I like long walks on the beach.',
          label: '',
          type: 'textarea',
          maxLength: 600,
          // max: 100,
          min: 3,
          required: true,
          error: '',
          example: 'Beta',
        },
      ],
      example: {
        output: 'Beta',
      },
    },
  ],
};

export default obj;
