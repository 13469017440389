const plans = {
  free: {
    title: 'Free',
    price: 0,
    description: 'Test before you buy, and upgrade your plan or cancel  at anytime.',
    fromColor: 'from-gray-400',
    toColor: 'to-blue-500',
    textColor: 'text-gray-500',
    shadowColor: '#9ca3af',
    detailsText: (
      <div>
        <p>Free plan</p>

        <ul style={{ paddingTop: 4, paddingLeft: 20 }} className="list-disc">
          <li>10 images</li>
          <li>Access to Chat-Ai</li>
          <li>Access features for a limited time</li>
        </ul>
      </div>
    ),
    isFreeTrial: true,
    priceId: process.env.REACT_APP_STRIPE_PRODUCT_FREE,
  },
  entry: {
    title: 'Entry',
    price: 7.99,
    description: 'Subscribe today to get access to our advanced AI-Tools.',
    fromColor: 'from-blue-400',
    toColor: 'to-violet-400',
    textColor: 'text-blue-400',
    shadowColor: '#60a5fa',
    detailsText: (
      <div>
        <p>Entry plan</p>

        <ul style={{ paddingTop: 4, paddingLeft: 20 }} className="list-disc">
          <li>230 Images</li>
          <li>Upscale 400+ images per month</li>
          <li>Access to Chat-Ai</li>
          <li>Access to all features</li>
        </ul>
      </div>
    ),
    priceId: process.env.REACT_APP_STRIPE_PRODUCT_ENTRY,
  },
  pro: {
    title: 'Pro',
    price: 19.99,
    description: 'Subscribe today to get access to our advanced AI-Tools.',
    fromColor: 'from-indigo-500',
    toColor: 'to-amber-400',
    textColor: 'text-indigo-500',
    shadowColor: '#6366f1',
    detailsText: (
      <div>
        <p>Pro plan</p>

        <ul style={{ paddingTop: 4, paddingLeft: 20 }} className="list-disc">
          <li>660 Images</li>
          <li>Upscale 1200+ images per month</li>
          <li>Access to Chat-Ai</li>
          <li>Access to all features</li>
        </ul>
      </div>
    ),
    priceId: process.env.REACT_APP_STRIPE_PRODUCT_PRO,
  },
};

export default plans;
