import Logo from '../Components/Logo';
import styles from './Chat.module.css';
import { XMarkIcon as CloseIcon } from '@heroicons/react/24/solid';
import MenuIcon from '../media/MenuIcon';
import { useCallback } from 'react';
import TypistBlocks from '../Components/TypistBlocks';

// Primary Chat Window
const ChatBox = ({
  chatLog,
  setChatInput,
  handleSubmit,
  chatInput,
  setMenuOpened,
  menuOpened,
  isNoCredits,
}) => (
  <section className={styles.chatBox}>
    <div className={styles.nav}>
      <button
        className={styles.menuButton}
        onClick={() => setMenuOpened((prevMenuOpened) => !prevMenuOpened)}
      >
        {menuOpened ? (
          <CloseIcon className="w-6 h-6 transition" />
        ) : (
          <MenuIcon className="w-6 h-6 transition" />
        )}
      </button>
    </div>

    <div id="chat-log" className={styles.messages}>
      {chatLog.filter(message => message.role !== 'function').map((message, index) => (
        <ChatMessage key={index} message={message} />
      ))}
    </div>
    <div className={styles.chatFormWrapper}>
      {isNoCredits && (
        <p style={{ borderRadius: 5 }} className="bg-gray-700 text-red-500 py-1 px-3 mb-1">
          No Credits left, please recharge your account on the profile page
        </p>
      )}

      <form className={styles.form} onSubmit={handleSubmit}>
        <input
          rows="1"
          value={chatInput}
          onChange={(e) => setChatInput(e.target.value)}
          className={
            styles.chatInputTextarea +
            ' ' +
            'bg-gray-300 hover:shadow-md shadow-2xl focus:shadow-2xl'
          }
        ></input>
        <button
          style={{ fontSize: 16 }}
          className="select-none py-2 px-3 border-t-2 border-gray-700 bg-gray-700 hover:bg-gray-600 hover:to-gray-700 text-blue-500  rounded-xl flex md:inline-flex font-medium text-lg cursor-pointer md:mx-0 transition"
          type="submit"
        >
          Submit
        </button>
      </form>
    </div>
  </section>
);

// Individual Chat Message
const ChatMessage = ({ message }) => {
  const scrollDown = useCallback(() => {
    const chatLog = document.getElementById('chat-log');
    chatLog.scrollTop = chatLog.scrollHeight;
  }, []);

  return (
    <div
      className={`${styles.chatMessage} ${
        message.role === 'assistant' && styles.assistant
      }`}
    >
      <div className={styles.chatMessageCenter}>
        <div
          className={`${styles.avatar} ${
            message.role === 'assistant' && styles.assistant
          }`}
          style={{
            backgroundImage:
              message.role === 'assistant'
                ? 'url(/code-e-avatar-bg.png)'
                : undefined,
          }}
        >
          {message.role === 'assistant' ? (
            <Logo
              style={{
                width: 30,
                height: 'initial',
                filter: 'drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.7))',
              }}
            />
          ) : (
            <div>You</div>
          )}
        </div>
        <div className={styles.message}>
          {typeof message.content !== 'string' ? (
            <span className={styles.blinker}>|</span>
          ) : message.role === 'assistant' && !message.isGreeting ? (
            <TypistBlocks onHeightResize={scrollDown}>
              {message.content}
            </TypistBlocks>
          ) : (
            message.content
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
