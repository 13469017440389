import Loader from './Loader';
import Typist from '../Components/Typist';
import {
  Square2StackIcon as DuplicateIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import CheckIcon from '../media/CheckIcon';

import CodeEditor from '@uiw/react-textarea-code-editor';
import DownloadableImage from './DownloadableImage';
import Cursor from './Cursor';

export const Output = inject('store')(
  observer(
    ({
      store,
      title,
      desc,
      Icon = ChevronRightIcon,
      RawIcon,
      output,
      code,
      language,
      outputs,
      outputErr,
      outputImages,
      loading,
      children,
      toColor,
      outputsColor,
      OutputsIcon,
    }) => {
      return (
        <div className="relative mb-12">
          <div className=" align-bottom bg-gray-800 md:rounded-3xl text-left  shadow-xl transform transition-all sm:align-middle transition shadow-md hover:shadow-2xl focus:shadow-2xl">
            <div className=" px-6 py-6">
              <div className="sm:flex sm:items-start">
                {loading ? (
                  <>
                    <Loader active={loading} className="w-6 h-6" />
                  </>
                ) : (
                  <>
                    {RawIcon ? (
                      RawIcon
                    ) : (
                      <div
                        className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-xl ${
                          output ? 'bg-emerald-300' : 'bg-gray-300'
                        } sm:mx-0 sm:h-10 sm:w-10 bg-gradient-to-r from-blue-400 to-violet-400`}
                      >
                        {Icon ? (
                          <Icon
                            className={`h-6 w-6 text-black`}
                            aria-hidden="true"
                          />
                        ) : null}
                      </div>
                    )}
                  </>
                )}

                <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div
                    as="h3"
                    className="text-lg leading-6 font-medium text-purple-500"
                  >
                    {title}
                  </div>
                  <p className="text-sm text-blue-500">{desc}</p>
                </div>
              </div>

              {outputErr ? (
                <div className="whitespace-pre-wrap min-w-full text-red-500 h-auto text-lg divide-y px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <Typist>{outputErr}</Typist>
                </div>
              ) : null}

              {outputImages && (
                <div className="mt-4">
                  {outputImages.map((image) => (
                    <DownloadableImage
                      key={image}
                      src={image.url}
                      filename={image.filename}
                      alt=""
                    />
                  ))}
                </div>
              )}

              {code ? null : output ? (
                <div className="whitespace-pre-wrap min-w-full text-blue-500 h-auto text-lg divide-y px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  {output ? <Typist>{output}</Typist> : null}
                </div>
              ) : null}

              {output && outputs && outputs.length ? (
                <div className="divide-y divide-dashed divide-gray-300">
                  {' '}
                  <div></div>
                  <div></div>
                </div>
              ) : null}

              {outputs && outputs.length ? (
                <Outputs
                  outputs={outputs}
                  outputsColor={outputsColor}
                  OutputsIcon={OutputsIcon}
                />
              ) : null}

              {code && code.length ? (
                <CodeEditor
                  style={{
                    fontFamily: 'JetBrains Mono',
                    fontSize: '1rem',
                  }}
                  padding={30}
                  language={language}
                  value={code}
                />
              ) : null}
              <QuickTools outputs={outputs} output={output} code={code} />
            </div>
          </div>
        </div>
      );
    },
  ),
);

export const QuickTools = inject('store')(
  observer(({ store, output, outputs, code }) => {
    return (
      <>
        {output || code || (outputs && outputs.length) ? (
          <div className="flex">
            <Shortcut
              className="p-1 rounded-lg cursor-pointer hover:bg-emerald-200 hover:text-emerald-700 relative group flex flex-col items-center group text-gray-300"
              onClick={() => store.copyToClipboard(output || code || outputs)}
            >
              <DuplicateIcon className="w-5 h-5" />
              <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white/25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                  Copy text to clipboard
                </span>
              </Tooltip>
            </Shortcut>
            <div className="flex-1"></div>
            <Shortcut
              className="p-1 rounded-lg cursor-pointer hover:bg-red-200 hover:text-red-700 relative group flex flex-col items-center group text-gray-300"
              onClick={() => store.reportToFeedback(output || code || outputs)}
            >
              <ExclamationCircleIcon className="w-5 h-5" />
              <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white/25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                  Report issue with output
                </span>
              </Tooltip>
            </Shortcut>
          </div>
        ) : null}
      </>
    );
  }),
);

const Tooltip = styled.div`
  display: none;
  white-space: nowrap;
`;

const Shortcut = styled.div`
  &:hover ${Tooltip} {
    display: flex;
  }
`;

function Outputs({ outputs, outputsColor, OutputsIcon }) {
  return (
    <div className="whitespace-pre-wrap min-w-full py-4 text-blue-500 h-auto text-lg divide-y">
      <Typist className="divide-y">
        {outputs.map((output, index) => (
          <div className="py-2 flex items-start" key={index}>
            <div
              className={`mr-4 flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-blue-200 text-blue-600`}
            >
              {OutputsIcon === false ? (
                `${index + 1}`
              ) : OutputsIcon ? (
                <OutputsIcon
                  className={`h-4 w-4 text-blue-600`}
                  aria-hidden="true"
                />
              ) : (
                <CheckIcon
                  className={`h-4 w-4 text-blue-600`}
                  aria-hidden="true"
                />
              )}
            </div>
            {output}
          </div>
        ))}
      </Typist>
    </div>
  );
}

export default Output;
