import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
  }
`;

const PageFullHeight = () => {
  return <GlobalStyles />;
};

export default PageFullHeight;
