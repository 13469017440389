const imageStandardGeneration = {
  type: 'image/generation',

  title: 'Pixel Art',
  desc: 'Generate a pixel image based on your text',
  category: '',

  permissions: ['user'],

  to: '/ai/image/generation/pixelart',
  api: '/image-ai/generation/pixelart',

  formatPrompt: (prompt) => `${prompt}`,

  output: {
    title: 'Completed',
    desc: 'Output.',
    Icon: false,
  },

  prompts: [
    {
      title: 'Image Generation',
      desc: 'Input what you would like to see.',
      prompts: [
        {
          type: 'textarea',
          title: 'Description',
          attr: 'prompt',
          value: '',
          example: 'Fractal landscape of geometric shapes and patterns, complex, intricate, abstract, digital art',
          placeholder: 'Cat on a shelf',
          label: '',
          maxLength: 800,
          // max: 100,
          min: 3,
          required: true,
          error: '',
        },
        // {
        //   type: 'slider',
        //   title: 'Steps',
        //   attr: 'steps',
        //   sliderMin: 50,
        //   sliderMax: 80,
        //   sliderStep: 1,
        //   value: 50,
        //   example: 50,
        //   label: '',
        //   required: true,
        //   error: '',
        // },
        // {
        //  type: 'select',
        //  title: 'Size',
        //  attr: 'size',
        //  value: '512x512',
        //  example: '512x512',
        //  selectOptions: [
        //    {
        //      label: '512 x 512',
        //      value: '512x512',
        //    },
        //    {
        //      label: '512 x 768',
        //      value: '512x768',
        //    },
            // {
            //   label: '1024 x 1024',
            //   value: '1024x1024',
            // },
        //  ],
        //  label: '',
        //  required: true,
        //  error: '',
       // },
       {
        hidden: true,
        attr: 'size',
        value: '1024x1024',
     }, 
       {
          hidden: true,
          attr: 'samples',
          value: 1,
        },
      ],
      example: {
        outputImages: ['/images/image-generation-output.png'],
      },
    },
  ],
};

export default imageStandardGeneration;
