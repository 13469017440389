import { Link } from 'react-router-dom';
import styles from './Chat.module.css';
import Slider from 'rc-slider';

const SideMenu = ({ setTemperature, temperature, clearChat, menuOpened }) => (
  <aside className={styles.sideMenu} data-opened={menuOpened}>
    <div className={styles.sideMenuContent}>
      <div style={{ marginBottom: 16 }}>
        <Link
          to="/"
          style={{
            textDecoration: 'underline',
            textDecorationColor: '#808080',
          }}
        >
          Back to Toolbox
        </Link>
      </div>

      <div className={styles.sideMenuButton} onClick={clearChat}>
        <span>+</span>
        New Chat
      </div>
      <div className="models">
        <label className={styles.sideLabel}>Slider</label>
        <div style={{ width: '100%', marginBottom: 12 }}>
          <Slider
            value={temperature}
            min={0}
            max={1}
            step={0.1}
            onChange={(v) => setTemperature(v)}
          />
        </div>

        <Button text="Logical" onClick={() => setTemperature(0)} />
        <Button text="Balanced" onClick={() => setTemperature(0.5)} />
        <Button text="Creative" onClick={() => setTemperature(1)} />
        <span className={styles.info}>
          The slider controls how creative or logical the response is, If you
          want a very logical response slide it to the left and if you want it
          to be very creative slide it the the right or use the buttons
          provided.
        </span>
      </div>
    </div>
  </aside>
);

const Button = ({ onClick, text }) => (
  <div className={styles.buttonPicker} onClick={onClick}>
    {text}
  </div>
);

export default SideMenu;
