import styles from './Chat.module.css';
import { useState, useEffect, useRef, useMemo } from 'react';
import SideMenu from './SideMenu';
import ChatBox from './ChatBox';
import PageFullHeight from '../Components/PageFullHeight';
import { Navigate, useLocation } from 'react-router-dom';

function Chat() {
  const store = window.store;

  const location = useLocation();

  const tool = useMemo(() => store.getToolByUrl(location.pathname), []);

  const [temperature, setTemperature] = useState(0);
  const [chatLog, setChatLog] = useState([
    {
      role: 'assistant',
      content: 'How can I help you?',
      isGreeting: true,
    },
  ]);

  const [chatInput, setChatInput] = useState('');
  const isSubmitting = useRef(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const [isNoCredits, setIsNoCredits] = useState(false);

  useEffect(() => {
    const chatLog = document.getElementById('chat-log');
    if (chatLog) {
      chatLog.scrollTop = chatLog.scrollHeight;
    }
  }, [chatLog]);

  if (!tool) return <Navigate to="/" />;

  // clear chats
  function clearChat() {
    setChatLog([]);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (isSubmitting.current || isNoCredits) return;

    isSubmitting.current = true;

    const chatLogNew = [...chatLog, { role: 'user', content: `${chatInput}` }];

    setChatInput('');
    setChatLog([...chatLogNew, { role: 'assistant', content: null }]);

    try {
      const { data } = await store.api.post(tool.api, {
        messages: chatLogNew.filter((m) => !m.isGreeting),
        temperature,
      });

      if (data.error === 'No Credits') {
        setIsNoCredits(true);
        setChatLog((prevChatLog) =>
          prevChatLog.filter((m) => m.content !== null),
        );
      } else {
        setChatLog((prevChatLog) => [
          ...prevChatLog.filter((m) => m.content !== null),
          ...data.messages
        ]);
      }
    } catch {
      setChatLog((prevChatLog) =>
        prevChatLog.filter((m) => m.content !== null),
      );
    } finally {
      isSubmitting.current = false;
    }
  }

  function handleTemp(temp) {
    if (temp > 1) {
      setTemperature(1);
    } else if (temp < 0) {
      setTemperature(0);
    } else {
      setTemperature(temp);
    }
  }

  return (
    <>
      <PageFullHeight />

      <div className={styles.Chat}>
        <SideMenu
          setTemperature={handleTemp}
          temperature={temperature}
          clearChat={clearChat}
          menuOpened={menuOpened}
        />
        <ChatBox
          chatInput={chatInput}
          chatLog={chatLog}
          setChatInput={setChatInput}
          handleSubmit={handleSubmit}
          setMenuOpened={setMenuOpened}
          menuOpened={menuOpened}
          isNoCredits={isNoCredits}
        />
      </div>
    </>
  );
}

export default Chat;
