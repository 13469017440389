const obj = {
  title: 'English to Portuguese',
  desc: 'Text to Text',
  category: 'Translate',
  // tags: [],
  permissions: ['user'],

  to: '/ai/transport',
  api: '/ai/transport',

  output: {
    title: 'Output',
    desc: 'The following translation has been made.',
    Icon: false,
  },

  prompts: [
    {
      title: 'Text Input',
      desc: 'Translation',
      // n: 1,
      prompts: [
        {
          title: 'Text to Text',
          attr: 'content',
          value: '',
          placeholder: 'Enter text here',
          label: '',
          type: 'textarea',
          maxLength: 2000,
          // max: 100,
          min: 3,
          required: true,
          error: '',
          example: 'Where is the next rest stop?',
        },
      ],
      example: {
        output: 'Onde é a próxima parada de descanso?',
      },
    },
  ],
};

export default obj;
