import React, { Component } from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import { computed, observable, makeObservable } from 'mobx';
import Header from '../Components/Header';
import {
  IdentificationIcon,
  ChatBubbleLeftEllipsisIcon as ChatAltIcon,
  UsersIcon,
  ArrowUturnLeftIcon as ReplyIcon,
  ChevronLeftIcon,
} from '@heroicons/react/24/outline';
import MainBody from '../Components/Body';
import Referral from './Referral';
import Feedback from './Feedback';
import { Helmet } from 'react-helmet-async';
import EnvIcon from './EnvIcon';
import { observer, inject } from 'mobx-react';
import Pricing from '../Pricing';
import withRouter from '../utils/withRouter';
import CheckIcon from '../media/CheckIcon';
import UserCircleIcon from '../media/UserCircleIcon';

@inject('store')
@observer
class Body extends Component {
  @computed get headerMessage() {
    if (this.props.store.profile.status === 'trialing') {
      return '7 Day Trial';
    }
    if (this.props.store.profile.status === 'active') {
      if (this.props.store.profile.cancel_at_period_end) {
        return `Set to cancel soon`;
      }
      return `${this.props.store.profile.plan} Plan`;
    }
    if (this.props.store.profile.status === 'incomplete') {
      return `${this.props.store.profile.plan} Plan Restarted`;
    }
    return 'Expired';
  }

  @computed get ifNotActive() {
    if (this.props.store.profile.cancel_at_period_end) {
      return 'Canceled';
    }
    if (this.props.store.profile.status === 'trialing') {
      return 'Trialing';
    }
    return false;
  }

  @computed get currentPeriodEnd() {
    // console.log(this.props.store.profile.current_period_end)
    if (
      this.props.store.profile.current_period_end &&
      this.props.store.profile.current_period_end.length > 0
    ) {
      var days_difference = Math.round(
        (new Date(this.props.store.profile.current_period_end).getTime() -
          new Date().getTime()) /
          (1000 * 60 * 60 * 24),
      );
      if (days_difference < 0) {
        return 0;
      }
      return days_difference;
    }
    return 0;
  }

  @observable plan = {
    plan: '',
  };

  componentDidMount() {
    this.props.store.refreshTokenAndProfile();
    makeObservable(this);
    this.init();
  }

  init = async () => {
    let res = await this.props.store.api.post('/user/stripe/plan');
    this.plan = {
      ...res.data,
    };
  };

  onBack = () => {
    this.props.navigate(`/my-profile`);
  };

  render() {
    return (
      <>
        <Header
          title={this.props.store.profile.email}
          desc={`${this.props.store.profile.fname} ${this.props.store.profile.lname}`}
          category="Your Profile"
          Icon={UserCircleIcon}
          textColor="text-blue-400"
          options={
            this.props.location.pathname !== '/my-profile'
              ? [
                  {
                    title: 'Back to profile',
                    Icon: ChevronLeftIcon,
                    onClick: this.onBack,
                  },
                ]
              : null
          }
        >
          <Routes>
            <Route
              path="/"
              element={
                <Helmet>
                  <title>{`My Profile - Code-e`}</title>
                </Helmet>
              }
            />
          </Routes>
        </Header>
        <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12">
          <Routes>
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/referral" element={<Referral />} />
            <Route path="/feedback" element={<Feedback />} />

            <Route
              path="/"
              element={
                <Grid>
                  {this.plan.status === 'trialing' ? (
                    <ToolForm
                      Icon={CheckIcon}
                      title={`Active Subscription`}
                      desc={`${this.plan.plan === 'Entry' ? '$30' : ''}${
                        this.plan.plan === 'Pro' ? '$90' : ''
                      } billing  immediately. Ends trial and starts billing plan.`}
                      to={this.props.store.baseURL + '/user/stripe/activate'}
                      api={this.props.store.api}
                      textColor="text-violet-500"
                      shadowColor="#4f46e5"
                    />
                  ) : null}

                  {this.plan.plan === 'None' ? (
                    <Tool
                      Icon={IdentificationIcon}
                      title={'Pricing Plans'}
                      api={this.props.store.api}
                      desc={'Upgrade, downgrade or cancel anytime.'}
                      to={'/my-profile/pricing'}
                      textColor="text-blue-400"
                      shadowColor="#60a5fa"
                    />
                  ) : null}

                  {this.headerMessage === 'Expired' ? null : (
                    <>
                      {this.ifNotActive ? null : (
                        <>
                          <ToolForm
                            Icon={IdentificationIcon}
                            title={'Cancel Subscription'}
                            api={this.props.store.api}
                            desc={
                              'Immediately cancelation of subscription and payments.'
                            }
                            to={this.props.store.baseURL + 'user/stripe/cancel'}
                            textColor={
                              this.props.store.profile.cancel_at_period_end
                                ? 'text-red-600'
                                : 'text-red-500'
                            }
                            shadowColor="#ef4444"
                          />
                        </>
                      )}

                      {this.props.store.profile.cancel_at_period_end ? (
                        <>
                          <ToolForm
                            Icon={CheckIcon}
                            title={'Reactivate Subscription'}
                            api={this.props.store.api}
                            desc={
                              'Immediately cancelation of subscription and payments.'
                            }
                            to={
                              this.props.store.baseURL + 'user/stripe/uncancel'
                            }
                            textColor={
                              this.props.store.profile.cancel_at_period_end
                                ? 'text-emerald-400'
                                : 'text-emerald-500'
                            }
                            shadowColor="#22c55e"
                          />
                        </>
                      ) : null}
                      <ToolForm
                        Icon={IdentificationIcon}
                        title={
                          this.props.store.profile.cancel_at_period_end
                            ? 'Manage Subscription'
                            : 'Update Subscription'
                        }
                        api={this.props.store.api}
                        desc={
                          'Change your plan, card details, or cancel the plan anytime.'
                        }
                        to={
                          this.props.store.baseURL +
                          'user/stripe/customer-portal'
                        }
                        textColor={
                          this.props.store.profile.cancel_at_period_end
                            ? 'text-blue-600'
                            : 'text-blue-500'
                        }
                        shadowColor="#60a5fa"
                      />
                    </>
                  )}

                  <Tool
                    Icon={UsersIcon}
                    title={'Referral Program'}
                    desc={
                      'Earn additional credits by referring people to the site!'
                    }
                    to={'/my-profile/referral'}
                    textColor="text-blue-400"
                    toColor="purple-400"
                    shadowColor="#60a5fa"
                  />

                  <Tool
                    Icon={ChatAltIcon}
                    title={'Feedback'}
                    desc={'Provide comments on your experience'}
                    to={'/my-profile/feedback'}
                    textColor="text-blue-400"
                    toColor="purple-400"
                    shadowColor="#60a5fa"
                  />

                  <ToolDiv
                    Icon={ReplyIcon}
                    title={'Log Out'}
                    desc={'Sign out of your account'}
                    onClick={this.props.store.handleLogout}
                    textColor="text-blue-400"
                    shadowColor="#60a5fa"
                  />
                </Grid>
              }
            />
          </Routes>
        </MainBody>
      </>
    );
  }
}

const Grid = ({ children }) => (
  <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 ">
    {children}
  </div>
);

const ToolDiv = ({
  Icon,
  title,
  desc,
  to,
  group,
  textColor,
  shadowColor,
  onClick,
}) => (
  <>
    <div className="flex relative " onClick={onClick}>
      <div
        style={{ boxShadow: `0 1px 10px 0 ${shadowColor}, 0 1px 2px -1px ` }}
        className={`flex-1 bg-gray-800 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-blue-500 cursor-pointer md:flex relative transform hover:scale-105  hover:text-blue-500`}
      >
        {Icon && (
          <div
            className={`md:flex-shrink-0 flex justify-start items-center ml-8 ${textColor}`}
          >
            <Icon className="h-16 w-16 mb-4 mt-4" />
          </div>
        )}
        <div className="p-4">
          <div
            className={`uppercase tracking-wide text-sm ${textColor} font-semibold leading-none`}
          >
            {group || ''}
          </div>
          <div
            href="#"
            className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-purple-400 leading-none"
          >
            {title}
          </div>
          <p className="mt-1 pr-1 text-sm ">{desc} </p>
        </div>
      </div>
    </div>
  </>
);

const ToolForm = ({
  Icon,
  title,
  desc,
  to,
  group,
  textColor,
  shadowColor,
  toColor,
  onClick,
  api,
}) => (
  <>
    <form action={to} method="POST" className="flex relative">
      <input
        type="hidden"
        name="token"
        value={api.defaults.headers.common['x-access-token']}
      />
      <button type="submit" className="flex-1 text-left">
        <div
          type="submit"
          style={{ boxShadow: `0 1px 10px 0 ${shadowColor}, 0 1px 2px -1px ` }}
          className={`flex-1 bg-white rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer md:flex relative transform hover:scale-105  hover:text-black`}
        >
          {Icon && (
            <div
              className={`md:flex-shrink-0 flex justify-start items-center ml-8 ${textColor}`}
            >
              <Icon className="h-16 w-16 mb-4 mt-4" />
            </div>
          )}
          <div className="p-4">
            <div
              className={`uppercase tracking-wide text-sm ${textColor} font-semibold leading-none`}
            >
              {group || ''}
            </div>
            <div className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none">
              {title}
            </div>
            <p className="mt-1 pr-1 text-sm ">{desc} </p>
          </div>
        </div>
      </button>
    </form>
  </>
);

const Tool = ({
  Icon,
  title,
  desc,
  to,
  group,
  textColor,
  toColor,
  shadowColor,
  onClick,
  api,
}) => (
  <Link to={to} className="flex relative">
    <div className="flex-1 text-left">
      <div
        style={{ boxShadow: `0 1px 10px 0 ${shadowColor}, 0 1px 2px -1px ` }}
        className={`flex-1 bg-gray-800 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-blue-500 cursor-pointer md:flex relative transform hover:scale-105  hover:text-blue-500`}
      >
        {Icon && (
          <div
            className={`md:flex-shrink-0 flex justify-start items-center ml-8 ${textColor}`}
          >
            <Icon className="h-16 w-16 mb-4 mt-4" />
          </div>
        )}
        <div className="p-4">
          <div
            className={`uppercase tracking-wide text-sm ${textColor} font-semibold leading-none`}
          >
            {group || ''}
          </div>
          <div className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-purple-400 leading-none">
            {title}
          </div>
          <p className="mt-1 pr-1 text-sm ">{desc} </p>
        </div>
      </div>
    </div>
  </Link>
);

export default withRouter(Body);
