function Button({ title, onClick, Icon, disabled, children, id }) {
  return (
    <button
      id={id}
      className={
        disabled
          ? `select-none py-3 px-6 border-t-2 border-gray-800 bg-gray-700 hover:bg-gray-600 disabled hover:to-gray-700 text-blue-500  rounded-xl flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition`
          : `select-none py-3 px-6 border-t-2 border-gray-800 bg-gray-700 hover:bg-gray-600 hover:via-blue-700 hover:to-gray-700 text-blue-500  rounded-xl flex md:inline-flex font-medium text-lg cursor-pointer mt-4 md:mx-0 transition`
      }
      onClick={disabled ? null : onClick}
    >
      {Icon && (
        <Icon
          className={
            disabled
              ? `h-5 w-6 mr-2 text-blue-500`
              : 'h-5 w-6 mr-2 text-blue-500'
          }
        />
      )}
      {title}
      {children}
    </button>
  );
}

export default Button;
