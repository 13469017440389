const imageStandardGeneration = {
  type: 'image/generation',

  title: 'Silhouette Design Generator',
  desc: 'Generate a Silhouette Design best for laser engraving.',
  category: 'Image',

  permissions: ['user'],

  to: '/ai/image/generation/Silhouette',
  api: '/image-ai/generation/Silhouette',

  formatPrompt: (prompt) => `${prompt}`,

  output: {
    title: 'Completed',
    desc: 'Can take up to 10+ seconds.',
    Icon: false,
  },

  prompts: [
    {
      title: 'Silhouette Design',
      desc: 'Input what you would like to see.',
      prompts: [
        {
          type: 'textarea',
          title: 'Description',
          attr: 'prompt',
          value: '',
          example:
            'Tiger',
          placeholder: 'Mountain landscape...',
          label: '',
          maxLength: 800,
          // max: 100,
          min: 3,
          required: true,
          error: '',
        },
        // {
        //   type: 'slider',
        //   title: 'Steps',
        //   attr: 'steps',
        //   sliderMin: 50,
        //   sliderMax: 80,
        //   sliderStep: 1,
        //   value: 50,
        //   example: 50,
        //   label: '',
        //   required: true,
        //   error: '',
        // },
       {
         type: 'select',
         title: 'Size',
         attr: 'size',
         value: '1024x1024',
         example: '1024x1024',
         selectOptions: [
           
           {
            label: '21:9 1536 x 640',
            value: '1536x640',
          },
          {
            label: '16:9 Screen Saver 1344 x 768',
            value: '1344x768',
          },
          {
            label: '3:2 1216 x 832',
            value: '1216x832',
          },
          {
            label: '5:4 1152x 896',
            value: '1152x 896',
          },
          {
             label: '1:1 1024 x 1024',
             value: '1024x1024',
           },
           {
            label: '4:5 896 x 1152',
            value: '896x1152',
          },
          {
            label: '2:3 832 x 1216',
            value: '832x1216',
          },
          {
            label: '9:16 Wallpaper 768 x 1344',
            value: '768x1344',
          },
        ],
        label: '',
         required: true,
         error: '',
       },
       {
          hidden: true,
          attr: 'samples',
          value: 1,
        },
      ],
      example: {
        outputImages: ['/images/image-generation-Blackandwhite.png'],
      },
    },
  ],
};

export default imageStandardGeneration;
