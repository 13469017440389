import styled from 'styled-components';
import Select from 'react-select';

const dimensions = [
  512, 576, 640, 704, 768, 832, 896, 960, 1024, 1088, 1152, 1216, 1280, 1344,
  1408, 1472, 1536, 1600, 1664, 1728, 1792, 1856, 1920, 1984, 2048,
].map((dimension) => ({
  value: dimension,
  label: dimension,
}));

const DimensionsInput = ({
  attr,
  onChange,
  value,
  error,
  totalMin,
  totalMax,
}) => {
  const onDimensionChange =
    (fieldKey) =>
    async ({ value }) =>
      onChange(fieldKey, value);

  return (
    <DimensionsInputRoot>
      <DimensionSelect
        id={attr}
        name={`${attr}.width`}
        value={value.width}
        otherValue={value.height}
        onChange={onDimensionChange('width')}
        placeholder="Width"
        error={error}
        totalMin={totalMin}
        totalMax={totalMax}
      />

      <span style={{ textAlign: 'center' }}>X</span>

      <DimensionSelect
        id={attr}
        name={`${attr}.height`}
        value={value.height}
        otherValue={value.width}
        error={error}
        onChange={onDimensionChange('height')}
        placeholder="Height"
        totalMin={totalMin}
        totalMax={totalMax}
      />
    </DimensionsInputRoot>
  );
};

const DimensionsInputRoot = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) 30px minmax(0, 1fr);
  align-items: center;
`;

const DimensionSelect = ({
  id,
  name,
  value,
  otherValue,
  onChange,
  placeholder,
  error,
  totalMin,
  totalMax,
}) => {
  return (
    <Select
      placeholder={placeholder}
      value={dimensions.find((dim) => dim.value === value)}
      options={dimensions}
      onChange={onChange}
      components={{
        IndicatorSeparator: () => null,
      }}
      isOptionDisabled={({ value }) => {
        const total = value * otherValue;
        return total < totalMin || total > totalMax;
      }}
    />
  );
  return (
    <div className="inline-block relative w-full mt-2">
      <select
        id={id}
        name={name}
        className={
          'block appearance-none w-full bg-white border border-gray-300 ' +
          'hover:border-gray-400 px-4 py-2 pr-8 rounded leading-tight focus:outline-none ' +
          `${error ? 'border-red-400' : 'border-gray-300'}`
        }
        value={value}
        onChange={onChange}
      >
        {dimensions.map((dimension, i) => {
          const total = dimension * otherValue;

          return (
            <option
              key={i}
              value={dimension}
              disabled={total < totalMin || total > totalMax}
            >
              {dimension}
            </option>
          );
        })}
      </select>

      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  );
};

export default DimensionsInput;
