import mapRegex from './mapRegex';
import hljs from 'highlight.js';

export const parseMainBlocks = (output) => {
  const pattern = /```.+?```/gs;

  return mapRegex(pattern, output, (item) => {
    if (item.isMatched) {
      const code = item.value.slice(3, -3);

      const language = (code.match(/^[+\w#/]+/i) ?? [])[0]?.toLowerCase();

      const isLanguageSupported = language
        ? !!hljs.getLanguage(language)?.name
        : false;

      return {
        type: 'multiline-code',
        value: code.slice(isLanguageSupported ? language.length : 0).trim(),
        language: isLanguageSupported ? language : undefined,
      };
    }

    return { type: 'text', value: item.value };
  });
};

export default parseMainBlocks;
