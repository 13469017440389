import React, { Component } from 'react';
import { PencilIcon, ClockIcon } from '@heroicons/react/24/outline';

import { Grid, Col } from '../Components/Body';
import EntryText from '../Components/EntryText';
import Button from '../Components/Button';
import Output from '../Components/Output';
import Countdown from 'react-countdown';

import { observable, makeObservable } from 'mobx';
import { observer, inject } from 'mobx-react';
import Logo from '../Components/Logo';

@inject('store')
@observer
class Referral extends Component {
  countdown;
  @observable output = '';
  @observable outputs = [];
  @observable outputError = '';

  @observable option = 'Start Using';
  @observable options = [];
  @observable currentOption = '';

  @observable loading = false;
  @observable date = Date.now() + 1000;

  // Basic Input
  @observable prompt = '';
  @observable promptError = '';
  @observable promptNumber = 0;

  // Options of Inputs
  @observable promptOptions = [];

  // Currently Selected Input Option
  @observable currentPromptOption = '';

  // Multiple Input Option
  @observable prompts = [];

  @observable feedbacks = [];

  @observable tool = {};

  constructor(props) {
    super(props);
    makeObservable(this);
    this.init();
  }

  init = async () => {
    this.refreshFeedback();
  };

  refreshFeedback = async () => {
    let response = await this.props.store.api.post('/user/feedback/view');
    this.feedbacks = [...response.data];
  };

  handleFeedback = async () => {
    try {
      await this.props.store.api.post('/user/feedback', {
        feedback: this.props.store.feedback,
      });
      this.refreshFeedback();
      this.output = 'Thank you for your feedback!';
      this.props.store.feedback = ``;
    } catch (err) {
      console.log(err);
      this.output =
        'There seems to have been an error, please try submitting again';
    }
  };

  onClick = async () => {
    this.loading = true;
    await this.handleFeedback();
    this.date = Date.now() + 10000;
    this.props.store.feedback = '';
    this.countdown.stop();
    this.countdown.start();
    this.loading = false;
  };
  onChange = (e) => {
    this.props.store.feedback = e.target.value;
  };

  render() {
    return (
      <>
        <Grid>
          <Col span="6">
            <EntryText
              title="Feedback"
              desc="Provide some feedback about your experience"
              prompt={this.props.store.feedback}
              onChange={this.onChange}
            />
            <Countdown
              ref={(countdown) => (this.countdown = countdown)}
              date={this.date}
              renderer={(props) => (
                <Button
                  title={
                    props.total
                      ? `Timeout ${props.total / 1000} secs`
                      : 'Submit Feedback'
                  }
                  disabled={props.total}
                  Icon={props.total ? ClockIcon : PencilIcon}
                  onClick={this.onClick}
                />
              )}
            />
          </Col>
          <Col span="6">
            <Output
              title={`CODE-E.AI`}
              desc={`Feedback Response`}
              RawIcon={
                <span className="mx-auto sm:mx-0 flex-shrink-0 flex items-center justify-center h-12 w-12">
                  <Logo style={{ width: 47, height: 'initial' }} />
                </span>
              }
              toColor={`blue-400`}
              loading={this.loading}
              output={this.output}
            />
            {this.feedbacks &&
              this.feedbacks.map((feedback, index) => (
                <Output
                  key={feedback._id}
                  title={`Feedback Received`}
                  desc={`${feedback.created}`}
                  Icon={PencilIcon}
                  toColor={feedback.response ? `emerald-600` : `gray-400`}
                  output={feedback.feedback}
                  outputs={feedback.response ? [feedback.response] : null}
                />
              ))}
          </Col>
        </Grid>
      </>
    );
  }
}

export default Referral;
