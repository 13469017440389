const stockChat = {
  title: 'Stock Chat',
  desc: 'Ask me questions about todays stock information.',
  category: 'Chat',

  permissions: ['user'],

  to: '/chat/stock',
  api: '/chat-ai/stock',
};

export default stockChat;
