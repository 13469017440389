import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import CheckIcon from '../media/CheckIcon';

@inject('store')
@observer
class EntryPrompt extends Component {
  render() {
    const { currentPrompt, disabled, index } = this.props;
    let hidden = currentPrompt === index ? false : true;

    return (
      <div
        className={`align-bottom bg-gray-800 md:rounded-3xl text-left transform transition-all sm:align-middle relative z-50 transition md:hover:shadow-md md:shadow-2xl md:focus:shadow-2xl md:mb-8  ${
          hidden ? 'hidden' : ''
        }`}
      >
        <div className="px-6 py-6">
          <div className="flex items-center">
            <div
              className={`flex-shrink-0 inline-flex items-center justify-center md:h-10 md:w-10 h-6 w-6 rounded-xl bg-gradient-to-r from-blue-400 to-purple-400 ${
                !disabled ? 'bg-purple-800' : 'bg-gray-300'
              } sm:mx-0 sm:h-10 sm:w-10  ${
                !disabled ? 'bg-purple-500' : 'bg-gray-300'
              } `}
            >
              <CheckIcon
                className={`h-3 w-3 md:h-5 md:w-5 ${
                  !disabled ? 'text-gray-800' : 'text-gray-500'
                } ${!disabled ? 'text-gray-800' : 'text-gray-500'}`}
                aria-hidden="true"
              />
            </div>

            <div className="mt-0 ml-4 text-left">
              <div
                as="h3"
                className="text-lg leading-6 font-medium text-purple-500"
              >
                {this.props.prompt.title || 'Entry Text'}
              </div>

              <p className="text-sm text-blue-500">
                {this.props.prompt.desc || 'Write a small bit of text'}
              </p>
            </div>
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default EntryPrompt;
