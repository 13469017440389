import { useEffect, useRef } from 'react';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

const useAutoHeightResize = ({ onResize }) => {
  const containerRef = useRef();
  const observerRef = useRef();

  useEffect(() => {
    const elem = containerRef.current;

    let prevHeight = elem.offsetHeight;
    const handle = () => {
      if (prevHeight !== elem.offsetHeight) {
         onResize();
      }
    };

    const observer = new ResizeObserver(handle);

    observerRef.current = observer;

    observer.observe(elem);

    return () => observer.disconnect();
  }, []);

  const disconnect = () => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
  };

  return { containerRef, disconnect };
};

export default useAutoHeightResize;
