import { useMemo } from 'react';

const FileUpload = ({ value, onChange, error, accept, isFetching }) => {
  const previewUrl = useMemo(() => {
    try {
      return value && typeof value !== 'string'
        ? URL.createObjectURL(value)
        : null;
    } catch (e) {
      return null;
    }
  }, [value]);

  return (
    <div className="mt-2">
      <label
        className={
          `text-center block bg-transparent text-gray-500 font-semibold ` +
          `py-2 px-4 border ${
            error ? 'border-red-400' : 'border-gray-300'
          } hover:border-gray-400 rounded cursor-pointer`
        }
      >
        Upload Image
        <input
          className="hidden"
          type="file"
          onChange={onChange}
          accept={accept}
        />
      </label>

      {(isFetching || previewUrl) && (
        <div
          style={{ overflow: 'hidden', height: isFetching ? 150 : undefined }}
          className="mt-2 max-w-full border border-gray-300 rounded"
        >
          {previewUrl && (
            <img
              style={{ height: 150 }}
              className="mx-auto max-w-full"
              src={previewUrl}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
