const processRegex = (string, pattern) => {
  const items = [];

  let match;

  while ((match = pattern.exec(string))) {
    const startIndex = match.index;
    const endIndex = startIndex + match[0].length;

    items.push({ startIndex, endIndex });
  }

  return items;
};

const mapRegex = (pattern, string, cb) => {
  let lastEndIndex = 0;
  const results = [];

  const pushResult = (result) => {
    results.push(cb({ ...result, idx: results.length }));
  };

  processRegex(string, pattern).forEach((matchedItem) => {
    if (lastEndIndex < matchedItem.startIndex) {
      pushResult({
        isMatched: false,
        value: string.slice(lastEndIndex, matchedItem.startIndex),
      });
    }

    pushResult({
      isMatched: true,
      ...matchedItem,
      value: string.slice(matchedItem.startIndex, matchedItem.endIndex),
    });

    lastEndIndex = matchedItem.endIndex;
  });

  const lastItem = string.slice(lastEndIndex);

  if (lastItem.length > 0) {
    pushResult({ isMatched: false, value: lastItem });
  }

  return results;
};

export default mapRegex;
