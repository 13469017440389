import React, { Component } from 'react';
import UserCircleIcon from './media/UserCircleIcon';

import { observer, inject } from 'mobx-react';
import Logo from './Components/Logo';
import Plan from './Components/Plan';
@inject('store')
@observer
class Pricing extends Component {
  render() {
    return (
      <>
        {this.props.store.profile.status ? null : (
          <div className="border-b border-gray-300 bg-white shadow-sm ">
            <div className="container flex mx-auto px-4 md:px-28 flex select-none">
              <div className="relative text-gray-400 focus-within:text-emerald-500 flex flex-1 "></div>
              <div
                onClick={this.props.store.handleLogout}
                className="cursor-pointer text-lg flex py-3 px-6 xl:py-4 xl:px-8 hover:bg-blue-300 rounded-t-md font-medium transition items-center"
              >
                <UserCircleIcon className="w-7 h-7 lg:mr-4 transition" />
                <div className="hidden lg:block"> Log Out</div>
              </div>
            </div>
          </div>
        )}

        <div className="container mx-auto px-8 py-4 lg:px-28 lg:py-12 lg:pb-64 select-none">
          {this.props.store.profile.status ? null : (
            <>
              <div className="text-center mb-4">
                <Logo style={{ margin: '0 auto' }} />
              </div>

              <h2 className="text-xl sm:text-2xl md:text-5xl text-gray-700 text-center">
                Start now
              </h2>
              <p className="text-lg sm:text-xl md:text-2xl text-gray-500 mb-14 mt-2 text-center">
                Pick a pricing option to begin using Code-e.Ai
              </p>
            </>
          )}

          <Grid>
            {this.props.store.profile.status ? null : (
              <Plan
                planName="free"
                baseURL={this.props.store.baseURL}
                api={this.props.store.api}
              />
            )}
            <Plan
              planName="entry"
              baseURL={this.props.store.baseURL}
              api={this.props.store.api}
            />
            <Plan
              planName="pro"
              baseURL={this.props.store.baseURL}
              api={this.props.store.api}
            />
          </Grid>
        </div>
      </>
    );
  }
}

const Grid = ({ children }) => (
  <div className="grid grid-cols-1 gap-12 mt-4 xl:grid-cols-3 ">{children}</div>
);

export default Pricing;
