const chatStandard = {
  title: 'Portuguese Assistant',
  desc: 'Faça-me perguntas ou para fazer uma tarefa.',
  category: 'Chat',

  permissions: ['user'],

  to: '/chat/transchat',
  api: '/chat-ai/transchat',
};

export default chatStandard;
