import { KeyIcon } from '@heroicons/react/24/outline';
import { Link, useParams } from 'react-router-dom';
import { useRef, useState } from 'react';
import Logo from '../Components/Logo';

const EnterNewPassword = () => {
  const store = window.store;
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [err, setErr] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const isSubmitting = useRef(false);
  const { resetToken } = useParams();

  const isFilled = !!password && !!confirmPassword;

  const ERRORS = {
    NOT_MATCHING: "password confirmation doesn't match the password",
    UNKNOWN_ERR: 'please check your internet connection then try again',
    EXPIRED: (
      <>
        the password reset link has been expired or already used you can{' '}
        <Link className="underline text-blue-500 text-sm" to="/forgot-password">
          create new one here
        </Link>
      </>
    ),
  };

  const error = ERRORS[err];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      return setErr('NOT_MATCHING');
    }

    if (isSubmitting.current) return;

    isSubmitting.current = true;

    try {
      e.preventDefault();

      await store.api.post('/auth-password/enter-new-password', {
        password,
        resetToken,
      });

      setIsSuccess(true);
      setErr(null);
      window.store.handleLogout();
    } catch (err) {
      setErr(err?.response?.data?.error ?? 'UNKNOWN_ERR');
    } finally {
      isSubmitting.current = false;
    }
  };

  return (
    <div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">
      <div className="text-center mb-8">
        <Logo style={{ margin: '0 auto ' }} />
      </div>
      <div className="min-w-full md:min-w-0 bg-white rounded-xl shadow-xl transform transition-all  transition shadow-md hover:shadow-2xl focus:shadow-2xl w-1/2">
        <div className="px-4 py-4 md:px-12 md:py-12">
          <form onSubmit={handleSubmit}>
            <div
              className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full ${
                isFilled ? 'bg-emerald-300' : 'bg-gray-300'
              }  ${isFilled ? 'bg-emerald-300' : 'bg-gray-300'} `}
            >
              <KeyIcon
                className={`h-8 w-8 ${
                  isFilled ? 'text-emerald-700' : 'text-gray-500'
                }`}
                aria-hidden="true"
              />
            </div>

            <div className="text-3xl text-center mt-3 mb-1 font-medium text-gray-900 ">
              Enter New Password
            </div>

            {!isSuccess && err !== 'EXPIRED' ? (
              <>
                <p className="text-lg text-center text-gray-500">
                  please enter your new password
                </p>

                <div className="flex flex-col flex-1">
                  <label className="text-gray-400 text-sm block mt-4 text-left">
                    Password
                  </label>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoFocus={true}
                    type="password"
                    minLength={8}
                    maxLength={255}
                    className="rounded-md text-lg px-4 py-2  border border-gray-300 "
                    placeholder="xxxxxxx"
                    required
                  />
                </div>

                <div className="flex flex-col flex-1">
                  <label className="text-gray-400 text-sm block mt-4 text-left">
                    Confirm password
                  </label>
                  <input
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    minLength={8}
                    maxLength={255}
                    type="password"
                    className="rounded-md text-lg px-4 py-2  border border-gray-300 "
                    placeholder="xxxxxxx"
                    required
                  />
                </div>

                <div className="flex flex-col">
                  <button
                    type="submit"
                    className="hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block"
                  >
                    Reset Password
                  </button>
                </div>
              </>
            ) : (
              isSuccess && (
                <div className="text-emerald-600 bg-emerald-50 rounded-md p-2 text-center mt-4">
                  the password has been reset successfully, you can{' '}
                  <Link className="underline text-blue-500 text-sm" to="/login">
                    login here
                  </Link>
                </div>
              )
            )}

            {error && (
              <div className="text-red-600 bg-gray-70 rounded-xl p-2 text-center mt-4">
                {error}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EnterNewPassword;
