const formatNumber = (numStrOrNum) => {
  const numStr = String(numStrOrNum);

  const splitted = [];

  for (let i = numStr.length - 1; i >= 0; i--) {
    if (typeof splitted[0] !== 'string' || splitted[0].length === 3) {
      splitted.unshift('');
    }

    splitted[0] = numStr[i] + splitted[0];
  }

  return splitted.join(',');
};

export default formatNumber;
