import { Link, Navigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import Logo from '../Components/Logo';
import Loading from '../Components/Loader';

const ERRORS = {
  UNKNOWN_ERR: 'please check your internet connection then try again',
  EXPIRED: (
    <>
      the verification link has been expired{' '}
      <Link className="underline text-blue-500" to="/signup">
        you can re-signup from here
      </Link>
    </>
  ),
};

const VerifyAccount = () => {
  const store = window.store;
  const [err, setErr] = useState(null);
  const [isSuccess, setIsSuccess] = useState(null);
  const { verificationToken } = useParams();
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) return;
    mounted.current = true;

    (async () => {
      let data;
      try {
        const res = await store.api.post('/auth/verify-account', {
          verificationToken,
        });

        data = res.data;
      } catch (err) {
        setErr(err?.response?.data?.error ?? 'UNKNOWN_ERR');
      }

      // first logout if user was already logged in
      store.handleLogout();

      // login
      if (data.token && data.profile) {
        store.loginWithDataTokenAndProfile(data);
      }

      setIsSuccess(true);
    })();
  }, []);

  return (
    <div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">
      <div className="text-center mb-8">
        <Logo style={{ margin: '0 auto ' }} />
      </div>
      <div className="min-w-full md:min-w-0 bg-gray-800 rounded-xl shadow-xl transform transition-all  transition shadow-md hover:shadow-2xl focus:shadow-2xl w-1/2">
        <div className="px-4 py-4 md:px-12 md:py-12">
          <div className="text-3xl text-center mt-3 mb-1 font-medium text-gray-800 mb-8">
            Verifying Your Account
          </div>

          {!err ? (
            <div className=" text-center">
              <Loading className="w-24 h-24" active />
            </div>
          ) : (
            <div className="text-red-600 bg-gray-700 rounded-md p-2 text-center">
              {ERRORS[err]}
            </div>
          )}
        </div>

        {isSuccess && <Navigate to="/" />}
      </div>
    </div>
  );
};

export default VerifyAccount;
