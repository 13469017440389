import { ThemeProvider } from 'styled-components';
import React, { Component } from 'react';

import { Provider } from 'mobx-react';
import { observer } from 'mobx-react';

import AppStore from './store';
import colors from 'tailwindcss/colors';
import {
  BrowserRouter as Router,
  Routes,
  Navigate,
  Route,
  useLocation,
} from 'react-router-dom';

import Header from './Header';
import Search from './Search';
import Pricing from './Pricing'; // <--- Add this line

import Dashboard from './Dashboard';

import Tool from './Core/Tool';

import Login from './Login/Login';

import Profile from './Profile';
import LoginSuccess from './Login/Success';

import ForgotPassword from './ForgotPassword/ForgotPassword';
import EnterNewPassword from './ForgotPassword/EnterNewPassword';
import LandingPage from './Landing/index';
import Chat from './Chat';
import VerifyAccount from './Login/VerifyAccount';

if (!window.store) {
  window.store = new AppStore();
}

@observer
class App extends Component {
  render() {
    // return (
    //   <Typist
    //     startDelay={0}
    //     typingDelay={7}
    //     // cursor={{
    //     //   show: true,
    //     //   blink: true,
    //     //   element: '|',
    //     //   hideWhenDone: true,
    //     //   hideWhenDoneDelay: 250,
    //     // }}
    //   >
    //     Storybook is a development environment for UI components. It helps you
    //     iterate faster on UI code. It allows you to browse a component library,
    //     view the different states of each component, and interactively develop
    //     and test components.\n\nI believe in Storybook’s value, and I’m sure it
    //     will improve your workflow. But I’m also going to share with you a
    //     different approach that can help you save some time without using a
    //     JavaScript framework.
    //   </Typist>
    // )

    const { isLoggedIn, profile, redirect } = window.store;

    return (
      <ThemeProvider theme={colors}>
        <Provider store={window.store}>
          <Router>
            <AppRoutes
              isLoggedIn={isLoggedIn}
              profile={profile}
              redirect={redirect}
            />
          </Router>
        </Provider>
      </ThemeProvider>
    );
  }
}

const AppRoutes = ({ isLoggedIn, profile, redirect }) => {
  const { pathname } = useLocation();

  let routes;

  if (isLoggedIn) {
    if (profile.status) {
      // Logged in with plan

      routes = [
        { path: '/', element: <Dashboard /> },
        { path: '/search', element: <Search /> },
        {
          path: '/ai/*',
          element: <Tool />,
        },
        { path: '/chat/*', element: <Chat /> },
        { path: '/my-profile/*', element: <Profile /> },
        { path: '/signup/failed', element: <Profile /> },
        { path: '/signup/success', element: <LoginSuccess /> },
      ];
    } else {
      // Logged in but no plan
      routes = [
        { path: '/signup/success', element: <LoginSuccess /> },
        { path: '/*', element: <Pricing /> },
      ];
    }
  } else {
    routes = [
      { path: '/', element: <LandingPage /> },
      { path: '/*', element: <Login /> },
    ];
  }

  const isShowHeader =
    pathname !== '/forgot-password' &&
    !pathname.startsWith('/chat') &&
    !pathname.startsWith('/enter-new-password/') &&
    isLoggedIn &&
    (profile.status || pathname === '/signup/success');

  return (
    <>
      {redirect ? <Navigate to={redirect} /> : null}

      {isShowHeader && <Header />}

      <Routes>
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route
          path="/enter-new-password/:resetToken"
          element={<EnterNewPassword />}
        />

        <Route
          path="/verify-account/:verificationToken"
          element={<VerifyAccount />}
        />

        {routes.map((route, idx) => (
          <Route key={idx} {...route} />
        ))}
      </Routes>
    </>
  );
};

export default App;
