import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { computed } from 'mobx';
import MainBody from './Components/Body';
import { Helmet } from 'react-helmet-async';

import { observer, inject } from 'mobx-react';
@inject('store')
@observer
class Body extends Component {
  @computed get permissions() {
    return this.props.store.tools.filter((tool) =>
      tool.permissions.some((r) =>
        this.props.store.profile.permissions.includes(r),
      ),
    );
  }

  @computed get beta() {
    return this.permissions.filter((tool) => tool.category === 'Beta new upcomming');
  }

 @computed get personal() {
    return this.permissions.filter((tool) => tool.category === 'Personal');
  }

  @computed get business() {
    return this.permissions.filter((tool) => tool.category === 'Business');
  }

  @computed get social() {
    return this.permissions.filter((tool) => tool.category === 'Social');
  }

  @computed get content() {
    return this.permissions.filter((tool) => tool.category === 'Content');
  }

  @computed get programming() {
    return this.permissions.filter((tool) => tool.category === 'Programming');
  }

  @computed get socialmedia() {
    return this.permissions.filter((tool) => tool.category === 'Socialmedia');
  }

  @computed get chat() {
    return this.permissions.filter((tool) => tool.category === 'Chat');
  }

  @computed get image() {
    return this.permissions.filter((tool) => tool.category === 'Image');
  }

  @computed get teacher() {
    return this.permissions.filter((tool) => tool.category === 'Teacher');
  }

  @computed get translate() {
  return this.permissions.filter((tool) => tool.category === 'Translate');
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{`CODE-ES Toolbox`}</title>
        </Helmet>
        <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12 ">
        {this.image.length ? (
            <>
              <Title title="Images" />
              <Grid>
                {this.image.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}
         
         {this.chat.length ? (
            <>
              <Title title="Chatbots" />
              <Grid>
                {this.chat.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}


          {/* {this.programming.length ? (
            <>
              <Title title="Programming" />
              <Grid>
                {this.programming.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null} */}

          {/* {this.content.length ? (
            <>
              <Title title="Written Content" />
              <Grid>
                {this.content.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null} */}


           {/* {this.translate.length ? (
            <>
              <Title title="Translate" />
              <Grid>
                {this.translate.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null} */}

          {/* {this.business.length ? (
            <>
              <Title title="Business" />
              <Grid>
                {this.business.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null} */}

          {/* {this.personal.length ? (
            <>
              <Title title="Personal" />
              <Grid>
                {this.personal.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null} */}

          {this.social.length ? (
            <>
              <Title title="online" />
              <Grid>
                {this.social.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {/* {this.socialmedia.length ? (
            <>
              <Title title="Social Media" />
              <Grid>
                {this.socialmedia.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          // ) : null} */}

          {this.teacher.length ? (
            <>
              <Title title="Ai Teachers" />
              <Grid>
                {this.teacher.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    toColor={tool.toColor}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}
        </MainBody>
      </>
    );
  }
}

export const Divider = () => (
  <div className="divide-y-2 divide-dashed divide-gray-800 py-8 md:py-12">
    {' '}
    <div></div>
    <div></div>
  </div>
);

export const Title = ({ title }) => (
  <h2 className="text-xl sm:text-2xl md:text-3xl text-blue-500 mb-4 md:mb-6">
    {title}
  </h2>
);

export const Grid = ({ children }) => (
  <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 ">
    {children}
  </div>
);

export const Tool = ({ Icon, title, desc, to, group }) => (
  <Link to={to || '/'} className="flex relative ">
    <div
      className={`bg-gray-800 flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-blue-500 cursor-pointer border border-gray-300 md:flex relative transform hover:scale-105  hover:text-blue-500`}
    >
      <div className="p-4">
        <div
          className={`uppercase tracking-wide text-sm text-blue-500 font-semibold leading-none`}
        >
          {group || 'New'}
        </div>
        <div
          href="#"
          className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-purple-400 leading-none"
        >
          {title}
        </div>
        <p className="mt-1 pr-1 text-sm ">{desc} </p>
      </div>
    </div>
  </Link>
);

export default Body;
