function Header({
  title,
  category,
  desc,
  Icon,
  options,
  currentOption,
  textColor,
  children,
}) {
  return (
    <div className="bg-gray-800 md:px-8 pt-4 shadow-lg mb-px border-b border-blue-600 overflow-hidden">
      <div className="container mx-auto px-4 md:px-28 flex items-center">
        <div>
          <div className={`text-sm font-medium ${textColor} -mb-1`}>
            {category}
          </div>
          <h2 className="text-2xl sm:text-3xl md:text-4xl text-purple-400 flex align-center -mb-1">
            {title}
          </h2>
          <p className="text-base text-blue-500 md:text-md lg:text-lg md:mt-2  lg:mx-0 ">
            {desc}
          </p>
        </div>
      </div>

      <div className="container mx-auto px-4 md:px-28 pt-4 select-none ">
        {options && (
          <div className="align-bottom bg-gray-700 text-left transform sm:align-middle transition inline-flex rounded-md ">
            {options.map((option, index) => (
              <Option key={index} currentOption={currentOption} {...option} />
            ))}
          </div>
        )}

        {children}
      </div>
    </div>
  );
}

export function Option({
  title,
  Icon,
  onClick,
  currentOption,
  text800,
  border500,
  bg100,
  bgHover200,
  bg300,
  text600,
}) {
  let active = currentOption === title;
  return (
    <div
      className={`lg:py-4 lg:px-8 py-3 px-6 flex transition ${
        active ? `${text800 ? text800 : 'text-emerald-800'}` : 'text-gray-500'
      } font-medium border-b -mb-px rounded-t-lg ${
        active
          ? `${border500 ? border500 : 'border-emerald-500'}`
          : 'border-gray-400'
      } ${active ? `${bg100 ? bg100 : 'bg-emerald-100'}` : 'white'} ${
        active
          ? `${bgHover200 ? bgHover200 : 'hover:bg-emerald-200'}`
          : 'hover:bg-gray-600'
      } cursor-pointer `}
      onClick={() => onClick(title)}
    >
      <div
        className={`md:mr-4  transition flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-md ${
          active ? `${bg300 ? bg300 : 'bg-emerald-300'}` : 'bg-purple-400'
        } ${
          active ? `${text600 ? text600 : 'text-emerald-600'}` : 'text-gray-600'
        }`}
      >
        <Icon
          className={`h-4 w-4 ${
            active
              ? `${text600 ? text600 : 'text-blue-500'}`
              : 'text-blue-500'
          }`}
          aria-hidden="true"
        />
      </div>
      <div className="hidden md:block">{title}</div>
    </div>
  );
}

export default Header;
