import { useState } from 'react';
import TypistComponent from 'react-typist-component';

const Typist = ({ onHeightResize, ...props }) => {
  const [done, setDone] = useState(false);

  return (
    <TypistComponent
      startDelay={0}
      typingDelay={7}
      cursor={done ? undefined : '|'}
      children={props.children}
      onTypingDone={() => {
        setDone(true);
        if (props.onTypingDone) props.onTypingDone();
      }}
    />
  );
};

export default Typist;
