import React, { Component } from 'react';
import {
  PencilIcon,
  ClockIcon,
  Square2StackIcon as DuplicateIcon,
} from '@heroicons/react/24/outline';

const InformationCircleIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

import { Helmet } from 'react-helmet-async';

import Header from '../Components/Header';
import Body, { Grid, Col } from '../Components/Body';
import Button from '../Components/Button';
import Output from '../Components/Output';
import Countdown from 'react-countdown';
import withRouter from '../utils/withRouter';

import { observable, makeObservable, computed } from 'mobx';
import { observer, inject } from 'mobx-react';

import EntryTabs from '../Components/EntryTabs';
import EntryPrompt from '../Components/EntryPrompt';
import EntryInput from '../Components/EntryInput';
import EntryN from '../Components/EntryN';

import Filter from 'bad-words';
import convertUrlToBlob from '../utils/convertUrlToBlob';
import formatNumber from '../utils/formatNumber';
let filterBadWords = new Filter();

@inject('store')
@observer
class Tool extends Component {
  isDidMountCalled = false;

  @observable tool = {};

  @observable.deep prompts = [];
  @observable currentPrompt = 0;
  @observable hideExample = false;
  @observable currentOption = 'Start Using';

  @observable error = '';

  @observable output = '';
  @observable outputErr = '';
  @observable outputs = [];
  @observable outputImages = [];
  @observable code = '';

  @observable loading = false;

  @observable date = Date.now() + 1000;
  countdown = [];

  constructor(props) {
    super(props);
    makeObservable(this);
    this.tool = this.props.store.getToolByUrl(this.props.location.pathname);
    if (!this.tool) {
      window.location.href = '/';
    } else {
      this.prompts = [...this.tool.prompts];
      this.hideExample = this.tool.hideExample ?? false;
    }
  }

  componentDidMount() {
    if (this.isDidMountCalled) return;
    this.isDidMountCalled = true;

    this.prompts.map(async (prompt) => {
      if (prompt.example.outputImages) {
        const outputImages = prompt.example.outputImages.map((image) => {
          const filenameParts = image.split('/');
          const filename = filenameParts[filenameParts.length - 1];
          return { url: image, filename };
        });

        prompt.example.outputImages = this.fetchImages(outputImages);
      }

      prompt.prompts.map((prompt) => {
        if (prompt.type === 'file') {
          const filenameParts = prompt.example.split('/');
          const filename = filenameParts[filenameParts.length - 1];
          prompt.example = convertUrlToBlob(prompt.example, filename);
        }
      });
    });
  }

  handleCurrentPrompt = (val) => {
    this.currentPrompt = val;
  };

  @computed get isGenerateButtonDisabled() {
    if (this.loading) {
      return true;
    }

    return false;
  }

  @computed get disabled() {
    const value = this.prompts[this.currentPrompt].prompts[0];
    if (!value || value.length < 1) {
      return true;
    }

    // this.prompts[this.currentPrompt].prompts[promptIndex].value
    return false;
  }

  @computed get isMinLength() {
    if (!this.props.prompt.min) {
      return false;
    }
    if (!this.props.prompt.type === 'number') {
      return false;
    }

    return false;
  }

  checkMinimumPrompts = () => {
    let shouldReturn = false;

    this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
      if (prompt.type === 'dimensions') {
        const total = prompt.value.width * prompt.value.height;

        if (
          typeof prompt.value.width !== 'number' ||
          typeof prompt.value.height !== 'number'
        ) {
          shouldReturn = true;
          prompt.error = `${prompt.title} is required`;
        } else if (
          !Number.isInteger(prompt.value.width / prompt.dimensionStep) ||
          !Number.isInteger(prompt.value.height / prompt.dimensionStep)
        ) {
          shouldReturn = true;
          prompt.error = `width and height must be specified in increments of ${prompt.dimensionStep}`;
        } else if (total < prompt.totalMin || total > prompt.totalMax) {
          shouldReturn = true;
          prompt.error =
            formatNumber(prompt.totalMin) +
            ' ≤ height * width ≤ ' +
            formatNumber(prompt.totalMax) +
            `\n(however your input result is ${formatNumber(total)})`;
        }
      } else if (prompt.type === 'integer-input') {
        if (typeof prompt.value !== 'number') {
          shouldReturn = true;
          prompt.error = `${prompt.title} is required`;
        } else if (prompt.value < prompt.minInteger) {
          shouldReturn = true;
          prompt.error =`${prompt.title} must be >= ${prompt.minInteger}`;
        }
      } else if (prompt.min) {
        if (prompt.value.length < prompt.min) {
          shouldReturn = true;
          prompt.error = `${prompt.title} needs to meet the minimum ${prompt.min} characters`;
        }
      } else if (prompt.required) {
        if (!prompt.value) {
          shouldReturn = true;
          prompt.error = `${prompt.title} is required`;
        }
      }
    });

    return shouldReturn;
  };

  clearExampleTimeout = [];

  onStartUsing = async () => {
    this.loading = false;
    this.error = '';
    this.clearExampleTimeout.forEach((item, index) => {
      clearTimeout(this.clearExampleTimeout[index]);
    });
    this.currentOption = 'Start Using';
  };

  onExample = async () => {
    this.loading = true;
    this.error = '';
    this.output = '';
    this.outputs = [];
    this.code = ``;

    this.currentOption = 'Example';

    let totalLength = 0;

    this.clearExampleTimeout.forEach((item, index) => {
      clearTimeout(this.clearExampleTimeout[index]);
    });

    this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
      if (
        ['file', 'select', 'dimensions', 'slider', 'integer-input'].includes(
          prompt.type,
        ) ||
        prompt.hidden
      )
        return;

      this.prompts[this.currentPrompt].prompts[promptIndex].value =
        prompt.type === 'file' ? null : '';
    });

    this.prompts[this.currentPrompt].prompts.forEach(
      async (prompt, promptIndex) => {
        if (!prompt.example || prompt.hidden) return;

        if (
          ['file', 'select', 'dimensions', 'slider', 'integer-input'].includes(
            prompt.type,
          )
        ) {
          prompt.value = await prompt.example;
          return;
        }

        for (
          let timeoutIndex = 0;
          timeoutIndex < prompt.example.length;
          timeoutIndex++
        ) {
          totalLength++;
          this.clearExampleTimeout[totalLength] = setTimeout(() => {
            this.prompts[this.currentPrompt].prompts[promptIndex].value +=
              prompt.example[timeoutIndex];
          }, 7 * totalLength);
        }
      },
    );

    totalLength++;

    if (this.prompts[this.currentPrompt].example.outputImages) {
      this.outputImages = [];

      setTimeout(async () => {
        this.outputImages = await this.prompts[this.currentPrompt].example
          .outputImages;
        this.loading = false;
        this.currentOption = 'Start Using';
      }, 1000);
    }

    if (this.prompts[this.currentPrompt].example.output) {
      this.clearExampleTimeout[totalLength] = setTimeout(() => {
        this.output = this.prompts[this.currentPrompt].example.output;
        totalLength++;
        this.clearExampleTimeout[totalLength] = setTimeout(() => {
          this.loading = false;
          this.currentOption = 'Start Using';
          const firstPrompt = this.prompts[this.currentPrompt].prompts[0];
          if (!['file', 'select'].includes(firstPrompt.type))
            firstPrompt.value += ' ';
        }, 7 * totalLength + this.prompts[this.currentPrompt].example.output.length * 7 + 500);
      }, 7 * totalLength + 500);
    }

    if (this.prompts[this.currentPrompt].example.code) {
      totalLength++;
      this.clearExampleTimeout[totalLength] = setTimeout(() => {
        this.code = `${this.prompts[this.currentPrompt].example.code}`;
        this.loading = false;
      }, 7 * totalLength + 500);
    }

    if (this.prompts[this.currentPrompt].example.outputs) {
      this.clearExampleTimeout[totalLength] = setTimeout(() => {
        this.outputs = this.prompts[this.currentPrompt].example.outputs;

        totalLength++;
        this.clearExampleTimeout[totalLength] = setTimeout(() => {
          this.loading = false;
          this.currentOption = 'Start Using';
          // this.prompts[this.currentPrompt].prompts[0].value += " "
        }, 7 * totalLength + 500);
      }, 7 * totalLength + 500);
    }
  };

  sanitizeAllPrompts = () => {
    this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
      if (!prompt.value) {
        return false;
      }
      if (prompt.type === 'number') {
        return false;
      }

      prompt.value = prompt.value.trim();

      if (filterBadWords.isProfane(prompt.value)) {
        prompt.error = 'Unsafe content , please try different language';
        throw Error('Unsafe content');
      }
    });
  };

  contentFilterFlagged = async (response) => {
    this.error = response.message;

    this.date = Date.now() + 5000;
    this.countdown.forEach((countdown) => {
      if (countdown) {
        countdown.stop();
        countdown.start();
      }
    });
    this.loading = false;
  };

  checkOutput = (output) => {
    if (output) {
      output = output.replace(/^\s+|\s+$/g, '');
      // output = output.replace(/\s{2,}/g, ' ')
    }
    return output;
  };

  @computed get language() {
    let language = '';
    this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
      if (prompt.attr === 'language') {
        language = `${prompt.value}`;
      }
    });
    return language;
  }

  fetchImages = async (images) => {
    const imagesPromises = images.map(
      (image) =>
        new Promise(async (resolve) => {
          const isLocal = !image.url.trim().startsWith('http');

          let blob;

          if (isLocal) {
            const res = await fetch(image.url);
            blob = await res.blob();
          } else {
            const res = await this.props.store.api.get(
              `/image-ai/view?url=${encodeURIComponent(image.url)}`,
              { responseType: 'blob' },
            );
            blob = res.data;
          }

          const reader = new FileReader();
          reader.onload = () =>
            resolve({ url: reader.result, filename: image.filename });
          reader.readAsDataURL(blob);
        }),
    );

    return Promise.all(imagesPromises);
  };

  onGenerateClick = async () => {
    try {
      this.error = '';
      this.output = '';
      this.outputErr = '';
      this.code = ``;
      this.outputs = [];
      this.outputImages = [];
      this.loading = true;

      let checkMinimumPrompts = this.checkMinimumPrompts();
      if (checkMinimumPrompts) {
        this.loading = false;
        return false;
      }
      // this.sanitizeAllPrompts()

      const isHaveFile = this.prompts[this.currentPrompt].prompts.some(
        (prompt) => prompt.type === 'file',
      );

      let postData;
      if (isHaveFile) {
        postData = new FormData();

        this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
          const setData = [prompt.attr, prompt.value];

          if (prompt.value.__filename) setData.push(prompt.value.__filename);

          postData.set(...setData);
        });

        postData.set('currentPrompt', this.prompts[this.currentPrompt].title);

        if (this.prompts[this.currentPrompt].n) {
          postData.set('n', this.prompts[this.currentPrompt].n);
        }
      } else {
        postData = {};

        this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
          postData[prompt.attr] = prompt.value;
        });

        postData.currentPrompt = this.prompts[this.currentPrompt].title;
        if (this.prompts[this.currentPrompt].n) {
          postData.n = this.prompts[this.currentPrompt].n;
        }
      }

      let response = await this.props.store.api.post(this.tool.api, postData);

      if (!response.data.success) {
        if (response.data.outputErr) {
          this.loading = false;
          this.outputErr = response.data.outputErr;
        } else {
          this.contentFilterFlagged(response.data);
          return false;
        }
      }

      if (response.data.output) {
        this.output = this.checkOutput(response.data.output);
      }

      if (response.data.code) {
        this.code = response.data.code;
      }

      if (response.data.outputs) {
        this.outputs = response.data.outputs;
      }

      if (response.data.outputImages) {
        this.outputImages = response.data.outputImages;
        this.loading = false;

        return;
      }

      this.date = Date.now() + 10000;
      this.countdown.forEach((countdown) => {
        if (countdown) {
          countdown.stop();
          countdown.start();
        }
      });
      this.loading = false;
    } catch (error) {
      this.countdown.forEach((countdown) => {
        if (countdown) {
          countdown.stop();
          countdown.start();
        }
      });
      this.loading = false;
    }
  };

  render() {
    // required for mobx to pick up deeply nested value
    const currentValue = this.prompts[this.currentPrompt].prompts[0].value;
    const credits = this.props.store.profile.credits;

    return (
      <>
        <Helmet>
          <title>{`${this.tool.title} Tools`}</title>
        </Helmet>

        <Header
          title={this.tool.title}
          desc={this.tool.desc}
          Icon={this.tool.Icon}
          category={this.tool.category}
          textColor="text-blue-400"
          options={[
            {
              title: 'Start Using',
              Icon: PencilIcon,
              text800: credits ? 'text-blue-500' : 'text-red-800',
              border500: credits ? 'border-blue-500' : 'border-blue-500',
              bg100: credits ? 'bg-gray-700' : 'bg-red-100',
              bgHover200: credits ? 'hover:bg-gray-600' : 'hover:bg-red-200',
              bg300: credits ? 'bg-purple-500' : 'bg-purple-500',
              text600: credits ? 'text-blue-500' : 'text-red-600',
              onClick: this.onStartUsing,
            },
            ...(this.hideExample
              ? []
              : [
                  {
                    title: 'Example',
                    Icon: InformationCircleIcon,
                    text800: 'text-blue-500',
                    border500: 'border-blue-500',
                    bg100: 'bg-blue-500',
                    bgHover200: 'hover:bg-gray-600',
                    bg300: 'bg-gray-300',
                    text600: 'text-blue-600',
                    onClick: this.onExample,
                  },
                ]),
          ]}
          currentOption={this.currentOption}
        />
        <Body>
          <Grid>
            <Col span="6">
              <EntryTabs
                prompts={this.prompts}
                currentPrompt={this.currentPrompt}
                onChange={this.handleCurrentPrompt}
              />

              {this.prompts.map((prompt, index) => (
                <EntryPrompt
                  prompt={prompt}
                  key={index}
                  index={index}
                  disabled={this.disabled}
                  currentPrompt={this.currentPrompt}
                >
                  {prompt.prompts.map(
                    (promptInput, index) =>
                      !promptInput.hidden && (
                        <EntryInput
                          prompt={promptInput}
                          key={index}
                          language={this.language}
                          index={index}
                          disabled={this.disabled}
                          onEnter={() =>
                            document
                              .getElementById('prompt-submit-button')
                              .click()
                          }
                        />
                      ),
                  )}

                  <div className="md:flex">
                    <Countdown
                      ref={(countdown) => (this.countdown[index] = countdown)}
                      date={this.date}
                      renderer={(props) => (
                        <Button
                          id="prompt-submit-button"
                          title={
                            props.total
                              ? `Timeout ${props.total / 1000} secs`
                              : 'Request'
                          }
                          disabled={
                            props.total || this.isGenerateButtonDisabled
                          }
                          Icon={
                            props.total
                              ? ClockIcon
                              : currentValue
                              ? DuplicateIcon
                              : PencilIcon
                          }
                          onClick={this.onGenerateClick}
                        />
                      )}
                    />
                    <EntryN
                      prompts={this.prompts}
                      currentPrompt={this.currentPrompt}
                    />
                  </div>

                  {this.error && (
                    <div className="mt-4">
                      <label
                        className={`${
                          this.error ? 'text-red-400' : 'text-gray-400'
                        } font-medium transition-all`}
                      >
                        {this.error}
                      </label>
                    </div>
                  )}
                </EntryPrompt>
              ))}
            </Col>
            <Col span="6">
              <Output
                title={this.tool.output.title}
                desc={this.tool.output.desc}
                Icon={this.tool.output.Icon || this.tool.Icon}
                toColor={this.tool.toColor}
                loading={this.loading}
                output={this.output}
                outputs={this.outputs}
                outputImages={this.outputImages}
                outputErr={this.outputErr}
                code={this.code}
                language={this.language}
                outputsColor={this.tool.output.color}
                OutputsIcon={this.tool.output.Icon}
              />
            </Col>
          </Grid>
        </Body>
      </>
    );
  }
}

export default withRouter(Tool);
