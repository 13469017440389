const obj = {
  title: "How 2 do's",
  desc: 'Turn small details into a professionl How to-do explanation guide.',
  category: 'Content',
  // tags: [],
  permissions: ['user'],

  to: '/ai/howto',
  api: '/ai/howto',

  output: {
    title: 'Completed',
    desc: 'Output.',
    Icon: false,
  },

  prompts: [
    {
      title: 'Output',
      desc: 'Input what you need done.',
      // n: 1,
      prompts: [
        {
          title: 'Content',
          attr: 'content',
          value: '',
          placeholder: 'Make me a how to do on...',
          label: '',
          type: 'textarea',
          maxLength: 600,
          // max: 100,
          min: 3,
          required: true,
          error: '',
          example: 'Beta',
        },
      ],
      example: {
        output: 'Beta no output.',
      },
    },
  ],
};

export default obj;
