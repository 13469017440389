import { memo, useEffect, useMemo, useState } from 'react';
import Typist from './Typist';
import hljs from 'highlight.js';
import parse from 'html-react-parser';
import 'highlight.js/styles/a11y-dark.css';
import parseMainBlocks from '../utils/parseMainBlocks';
import useAutoHeightResize from '../hooks/useAutoHeightResize';
import parseTextBlock from '../utils/parseTextBlock';

const TypistCode = ({ style, children, show, language, ...rest }) => {
  const lines = useMemo(() => {
    const { value: htmlResult } = language
      ? hljs.highlight(children, { language })
      : hljs.highlightAuto(children);

    return parse(htmlResult);
  }, []);

  if (!show) return null;

  return (
    <pre
      style={{
        background: '#282a36',
        color: '#f8f8f2',
        display: 'block',
        overflowX: 'auto',
        padding: '0.5em',
        minHeight: 40,
        ...style,
      }}
    >
      <code style={{ whiteSpace: 'pre-wrap' }}>
        <Typist {...rest}>{lines}</Typist>
      </code>
    </pre>
  );
};

const TypistBlocks = ({ children, onHeightResize }) => {
  const blocks = useMemo(() => parseMainBlocks(children), [children]);

  const { containerRef, disconnect } = useAutoHeightResize({
    onResize: onHeightResize,
  });

  const [curIdx, setCurIdx] = useState(0);

  const done = curIdx === blocks.length;

  useEffect(() => {
    if (done) disconnect();
  }, [done]);

  const onTypingDone = () => setCurIdx((prev) => prev + 1);

  return (
    <div style={{ width: '100%', height: 'auto' }} ref={containerRef}>
      {blocks.map((block, idx) => {
        const isShow = curIdx >= idx;
        const style = idx > 0 ? { marginTop: 8 } : undefined;

        if (block.type === 'multiline-code') {
          return (
            <TypistCode
              key={idx}
              style={style}
              show={isShow}
              onTypingDone={onTypingDone}
              language={block.language}
            >
              {block.value}
            </TypistCode>
          );
        }

        return (
          isShow && (
            <div key={idx} style={style}>
              <Typist onTypingDone={onTypingDone}>
                {parseTextBlock(block.value)}
              </Typist>
            </div>
          )
        );
      })}
    </div>
  );
};

export default memo(TypistBlocks, () => true);
