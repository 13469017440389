const chatStandard = {
  title: 'DEV-WIZARD',
  desc: 'Superior knowledge of cloud operations, UI/UX design and coding responds with secure practices.',
  category: 'Chat',

  permissions: ['user'],

  to: '/chat/devwizard',
  api: '/chat-ai/devwizard',
};

export default chatStandard;
