const chatStandard = {
  title: 'Javascript Chat',
  desc: 'Ask me questions about javascript.',
  category: 'Chat',

  permissions: ['user'],

  to: '/chat/javachat',
  api: '/chat-ai/javachat',
};

export default chatStandard;
