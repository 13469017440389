const obj = {
  title: 'Business Ads',
  desc: 'Create professional ads from small details about your business and what you do.',
  category: 'Business',
  // tags: [],
  permissions: ['user'],

  to: '/ai/admaker',
  api: '/ai/admaker',

  output: {
    title: 'Output',
    desc: 'The following ad has been made.',
    Icon: false,
  },

  prompts: [
    {
      title: 'Entry Text',
      desc: 'Input details about your business.',
      // n: 1,
      prompts: [
        {
          title: 'Content',
          attr: 'content',
          value: '',
          placeholder: 'Enter text here',
          label: '',
          type: 'textarea',
          maxLength: 600,
          // max: 100,
          min: 3,
          required: true,
          error: '',
          example:
            'make me an ad for a hotdog stand that sells hotdogs for .50 cents on sundays.',
        },
      ],
      example: {
        output:
          'Heads up Hot Dog lovers! This Sunday you can indulge in a delicious hotdog for just .50 cents at our Hotdog Stand! So come on down and grab one or more! of our mouth-watering hotdogs and enjoy the best deal in town. We look forward to seeing you this Sunday!',
      },
    },
  ],
};

export default obj;
