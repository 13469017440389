import jobad from './jobad';
import summarize from './summarize';
import codeInterpret from './interpret';
import intro from './intro';
import emailmaker from './emailmaker';
import recipe from './recipe';
import resume from './resume';
import chatStandard from './chatStandard';
import review from './review';
import translatespan from './translatespan';
import admaker from './admaker';
import businessname from './businessname';
import howto from './howto';
import coverletter from './coverletter';
import social from './social';
import date from './date';
import transport from './transport';
import imageStandardGeneration from './imageStandardGeneration';
import imageStandardEdit from './imageStandardEdit';
import imageStandardVariation from './imageStandardVariation';
import imageStandardUpscale from './imageStandardUpscale';
import pixelart from './pixelart';
import javachat from './javachat';
import python from './python';
import transchat from './transchat';
import stockChat from './stockChat';
import Silhouette from './Silhouette';
import devwizard from './devwizard';

const TOOLS = [
  imageStandardGeneration,
  Silhouette,
  imageStandardVariation,
  imageStandardEdit,
  imageStandardUpscale,
  pixelart,
  codeInterpret,
  intro,
  jobad,
  summarize,
  emailmaker,
  recipe,
  resume,
  chatStandard,
  devwizard,
  javachat,
  transchat,
  python,
  review,
  translatespan,
  admaker,
  businessname,
  howto,
  coverletter,
  social,
  date,
  transport,
  stockChat,
];

export default TOOLS;
