const Logo = ({ className, style }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 93.4 56.7"
      xmlSpace="preserve"
      className={className}
      style={{ height: 78, display: 'block', ...style }}
    >
      <g>
        <path d="M41.3,46.2l-4.8,9.6H14L41.3,1.1l27.3,54.7H46L41.3,46.2L41.3,46.2z" />
        <path d="M92.6,55.8H71V2.6h21.6V55.8z" />
      </g>
      <g>
        <path
          d="M7.1,49.9C2.7,47.4-0.3,41.1,1.4,38c1-1.8,2.2-2,3.7-1.1c1.6,0.9,2.9,2.9,2.2,4.2c-0.2,0.4-0.4,0.6-0.7,0.6
     c-0.5-0.7-1.6-1.5-2-1.7c-0.8-0.5-1.2-0.4-1.4,0c-0.5,1,1.9,4.8,4.9,6.5c1.7,0.9,2.8,0.9,3.6-0.6c0.8-1.5,0.6-3.9-0.4-5.9
     c0.2-0.4,0.5-0.6,0.9-0.4c1.4,0.8,3,5.9,1.3,9C12.2,50.9,10,51.4,7.1,49.9L7.1,49.9z"
        />
        <path
          d="M13.2,33.3c0.9,0.5,1.5,1,2.2,1.9c0.1,0,0.2-0.1,0.3-0.3c0.3-0.5,0.1-1.2-0.5-2.3c0.2-0.3,0.6-0.5,1-0.3
     c0.8,0.4,1.8,2.5,0.9,4c-0.2,0.3-0.3,0.5-0.6,0.6c0.7,1.5,0.9,2.8,0.2,4c-0.7,1.2-2.1,1.8-3.8,0.8c-2.9-1.6-4.6-5.1-3.6-7
     c0.2-0.5,0.8-0.9,1.4-0.6c-0.1-0.3-0.1-0.5,0.1-0.8C11.3,32.7,12.4,32.9,13.2,33.3L13.2,33.3z M15.2,38.8c0.2-0.4,0.1-1.1-0.6-1.8
     c-0.6-0.2-1.2-0.4-1.6-0.7c-0.6-0.3-1.5-1-1.9-1.5c-0.1,1.4,1.1,3.2,2.6,4C14.6,39.3,15,39.2,15.2,38.8z"
        />
        <path
          d="M13.5,27.9c0.3-0.5,0.8-0.6,1.2-0.4c0.1,0,0.1,0.1,0.2,0.1c0.1,1.8,1.7,3.4,3,4c0.6,0.3,0.9,0.3,1.1-0.1
     c0.4-0.7-1.8-3.3-4.7-4.9c-2.1-1.1-3.7-0.6-4.7,1c-0.2,0.1-0.5,0-0.7-0.1c-0.8-0.5-1.3-1.6-0.4-3.2c1.1-2,3.5-2.4,6.2-0.9
     c5.5,3.1,7.5,7.9,6.3,10.1c-0.8,1.4-2.2,1.8-4.2,0.7C14.1,32.8,12.7,29.3,13.5,27.9L13.5,27.9z"
        />
        <path
          d="M20.6,26.9c-2.7-1.5-4.2-5.2-3.3-6.9c0.7-1.2,1.6-1.6,2.9-0.9c1.5,0.8,2.2,2.3,1.4,5.1c0.8,0.4,1.3,0.5,1.7-0.2
     c0.7-1.2,0.5-3.3-0.5-5.2c0.2-0.4,0.7-0.5,1-0.4c1.3,0.7,2.5,5.2,1.3,7.5C24,27.8,22.5,28,20.6,26.9L20.6,26.9z M18.9,21.5
     c-0.1,0.2,0.2,1.1,1.4,1.9c0.4-1.1-0.1-1.6-0.6-1.9C19.4,21.3,19.1,21.3,18.9,21.5z"
        />
        <path
          d="M24.4,12.5c0.3-0.5,1-0.6,1.6-0.3c1.2,0.7,1,2.4-0.3,5.7c-0.2,0.2-0.6,0.2-0.9,0.1c-0.5-0.3-0.6-0.7-0.5-1.7
     C24.5,14.8,24.6,14,24.4,12.5z"
        />
        <path
          d="M28.6,12.4c-2.7-1.5-4.2-5.2-3.3-6.9c0.7-1.2,1.6-1.6,2.9-0.9c1.5,0.8,2.2,2.3,1.4,5.1c0.8,0.4,1.3,0.5,1.7-0.2
     c0.7-1.2,0.5-3.3-0.5-5.2C31,4,31.4,3.9,31.7,4c1.3,0.7,2.5,5.2,1.3,7.5C32,13.3,30.5,13.5,28.6,12.4L28.6,12.4z M26.9,7
     c-0.1,0.2,0.2,1.1,1.4,1.9c0.4-1.1-0.1-1.6-0.6-1.9C27.3,6.8,27,6.8,26.9,7z"
        />
        <path d="M34.5,4.9c-0.9-0.5-1-1.7-0.5-2.7c0.5-0.8,1-1.1,1.4-0.8c1,0.6,2.1,2.1,1.6,3.1C36.6,5.3,35.4,5.5,34.5,4.9L34.5,4.9z" />
      </g>
    </svg>
  );
};

export default Logo;
