const obj = {
  title: 'Review Maker',
  desc: 'Turn small details into a professional review.',
  category: '',
  // tags: [],
  permissions: ['user'],

  to: '/ai/review',
  api: '/ai/review',

  output: {
    title: 'Review',
    desc: 'Finished review.',
    Icon: false,
  },

  prompts: [
    {
      title: 'Make a review from small details.',
      desc: 'Company name, food, goods, etc...',
      // n: 1,
      prompts: [
        {
          title: 'Content',
          attr: 'content',
          value: '',
          placeholder: 'Enter text here',
          label: '',
          type: 'textarea',
          maxLength: 600,
          // max: 100,
          min: 3,
          required: true,
          error: '',
          example: 'Hannahs muffin shop',
        },
      ],
      example: {
        output:
          'I recently had the pleasure of visiting Hannahs Muffin Shop. From the moment I stepped in the door, I was welcomed with friendly smiles and a cozy atmosphere. The service was prompt and the staff was knowledgeable about their offerings. The muffins were delicious - light and fluffy, with a perfect amount of sweetness. I even sampled one of their specialty muffins, which was a combination of blueberry and cinnamon - a delightful surprise! Overall, I had a wonderful experience and would highly recommend.',
      },
    },
  ],
};

export default obj;
