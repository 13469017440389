const obj = {
  title: 'Explain Code',
  desc: 'Interpret some code based on the language, code, and syntax provided.',
  category: 'Programming',
  // tags: [],
  permissions: ['user'],

  to: '/ai/code/interpret',
  api: '/ai/code/interpret',

  output: {
    title: 'Code Explained',
    desc: 'The following code does:',
  },

  prompts: [
    {
      title: 'Interpret Code',
      desc: 'Imput code details below',
      // n: 1,
      prompts: [
        {
          title: 'Language',
          attr: 'language',
          value: '',
          placeholder: 'JavaScript...',
          label: 'Which language are you using, will enable markup highlights',
          type: 'text',
          maxLength: 40,
          // options: [{ title: "2nd Grader", value: "2nd Grader", desc: "Explain this like I'm 5 years old", Icon: AnnotationIcon },],
          // max: 100,
          min: 3,
          required: true,
          error: '',
          example: `JavaScript`,
        },
        {
          title: 'Code Editor',
          attr: 'content',
          value: '',
          placeholder: 'function Name(attr){...',
          label: 'Place some code in the text box to see what it does',
          type: 'code',
          maxLength: 2000,
          // max: 100,
          min: 3,
          required: true,
          error: '',
          example: `function HelloWorld(text){ 
	let text || "Hello World"; 
	console.log(text);
}`,
        },
      ],
      example: {
        output: ``,
        outputs: [
          'The code above is a function definition.',
          'It defines a new function called `HelloWorld` that takes a single argument called `text`',
          'The body of the function is a single line of code that prints out the value of `text` if it is defined, or `Hello World` if it is not defined.',
        ],
      },
    },
  ],
};

export default obj;
