import { KeyIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useRef, useState } from 'react';
import Logo from '../Components/Logo';

const ForgotPassword = () => {
  const store = window.store;
  const [email, setEmail] = useState('');
  const [err, setErr] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const isSubmitting = useRef(false);

  const isFilled = !!email;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting.current) return;

    isSubmitting.current = true;

    try {
      e.preventDefault();

      await store.api.post('/auth-password/forgot-password', {
        email,
      });

      setIsSuccess(true);
      setErr(null);
    } catch (err) {
      setErr(err?.response?.data?.error ?? 'UNKNOWN_ERR');
    } finally {
      isSubmitting.current = false;
    }
  };

  return (
    <div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">
      <div className="text-center mb-8">
        <Logo style={{ margin: '0 auto ' }} />
      </div>
      <div className="min-w-full md:min-w-0 bg-gray-800 rounded-xl shadow-xl transform transition-all  transition shadow-md hover:shadow-2xl focus:shadow-2xl w-1/2">
        <div className="px-4 py-4 md:px-12 md:py-12">
          <form onSubmit={handleSubmit}>
            <div
              className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full 
              ${isFilled ? 'bg-purple-400' : 'bg-gray-700'} `}
            >
              <KeyIcon
                className={`h-8 w-8 ${
                  isFilled ? 'text-blue-500' : 'text-blue-500'
                } `}
                aria-hidden="true"
              />
            </div>

            <div className="text-3xl text-center mt-3 mb-1 font-medium text-purple-400 ">
              Forgot Password
            </div>

            {!isSuccess ? (
              <>
                <p className="text-lg text-center text-blue-500">
                  please enter your email and we'll send you a link to reset
                  your password
                </p>

                <div className="flex flex-col flex-1">
                  <label className="text-blue-500 text-sm block mt-4 text-left">
                    Email Address
                  </label>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoFocus={true}
                    type="email"
                    name="email"
                    className="rounded-md text-lg px-4 py-2  border border-blue-500 "
                    placeholder="example@email.com"
                    required
                  />
                </div>

                <div className="flex flex-col">
                  <button
                    type="submit"
                    className="hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-700 text-blue-500 mt-4 border border-blue-500 inline-block"
                  >
                    Reset Password
                  </button>

                  <div className="mt-4 text-center">
                    <Link
                      className="text-blue-500 text-sm"
                      to={store.isLoggedIn ? '/' : '/login'}
                    >
                      back to {store.isLoggedIn ? 'Home' : 'Log In'}
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <div className="text-blue-500 bg-gray-700 rounded-xl p-2 text-center mt-4">
                <p>
                  the password reset link has been sent successfully to your
                  email
                </p>

                <p>"{email}"</p>

                <p>
                  please check for the email in your inbox, promotional tab,
                  ...etc
                </p>
              </div>
            )}

            {err && (
              <div className="text-red-600 bg-gray-700 rounded-xl p-1 text-center mt-4">
                {err === 'NOT_FOUND' ? (
                  <>
                    <p>The email you entered is not registered.</p>
                    <p>
                      you can{' '}
                      <Link className="underline text-blue-500" to="/signup">
                        sign up here
                      </Link>
                    </p>
                  </>
                ) : (
                  'please check your network connection'
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
