const obj = {
  title: 'Business Names',
  desc: 'Create professional business names from small details about your business and what you do.',
  category: 'Business',
  // tags: [],
  permissions: ['user'],

  to: '/ai/businessname',
  api: '/ai/businessname',

  output: {
    title: 'Output',
    desc: 'The following name has been generated.',
    Icon: false,
  },

  prompts: [
    {
      title: 'Entry Text',
      desc: 'Input details about your business.',
      // n: 1,
      prompts: [
        {
          title: 'Content',
          attr: 'content',
          value: '',
          placeholder: 'Enter text here',
          label: '',
          type: 'textarea',
          maxLength: 600,
          // max: 100,
          min: 3,
          required: true,
          error: '',
          example:
            'Make me an business name for a hotdog stand that sells hotdogs at sports events.',
        },
      ],
      example: {
        output: 'Sports Dogz.',
      },
    },
  ],
};

export default obj;
