import React, { Component } from 'react';
import { computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import TextareaAutosize from 'react-textarea-autosize';
import CheckIcon from '../media/CheckIcon';

// import EntryDropdown from './EntryDropdown'

import CodeEditor from '@uiw/react-textarea-code-editor';
import FileUpload from './FileUpload';
import Slider from '../../node_modules/rc-slider/lib/Slider';
import styled from 'styled-components';
import parseLineBreaks from '../utils/parseLineBreaks';
import DimensionsInput from './DimensionsInput';

@inject('store')
@observer
class EntryInput extends Component {
  onChange = async (e) => {
    this.props.prompt.value = e.target.value;
    this.props.prompt.error = '';
  };

  onFileChange = async (e) => {
    const [file] = e.target.files;
    if (file) this.props.prompt.value = file;
    this.props.prompt.error = '';
  };

  onChangeDropdown = async (e) => {
    this.props.prompt.value = e.value;
    this.props.prompt.error = '';
  };

  onIntegerChange = ({ target: { value } }) => {
    this.props.prompt.error = '';

    if (value === '') {
      this.props.prompt.value = null
    } else if (/^[0-9]+$/.test(value)) {
      this.props.prompt.value = Number(value)
    }
  }

  // handleEditorChange = (e, value) => {
  // 	console.log(e)
  // 	this.props.prompt.value = e.target.value
  // 	this.props.prompt.error = ""
  // };

  @computed get isMinLength() {
    // console.log(this.props.prompt.value.length, this.props.prompt.min)
    if (!this.props.prompt.min) {
      return false;
    }
    if (!this.props.prompt.type === 'number') {
      return false;
    }
    if (this.props.prompt.value.length === 0) {
      return false;
    }
    if (this.props.prompt.value.length < this.props.prompt.min) {
      return true;
    }
    return false;
  }

  @computed get isLongEnoughToDrop() {
    return this.props.prompt.value.length < 30;
  }

  @computed get currentNumberOfCharacters() {
    let currentCharacters = this.props.prompt.value.length;
    let maxCharacters = 80;
    if (this.props.prompt.type === 'textarea') {
      maxCharacters = 400;
    }
    if (this.props.prompt.maxLength) {
      maxCharacters = this.props.prompt.maxLength;
    }
    if (this.props.prompt.max) {
      maxCharacters = this.props.prompt.max;
    }

    return `${currentCharacters}/${maxCharacters}`;
  }

  render() {
    const { Icon, onEnter = () => {} } = this.props;

    return (
      <div className={`${this.props.size ? 'mt-4' : 'mt-4'}`}>
        {this.props.prompt.title && (
          <label
            htmlFor={this.props.prompt.attr}
            className="text-gray-600 font-medium text-md"
          >
            {this.props.prompt.title}
          </label>
        )}

        <div className="relative flex flex-col">
          {['select', 'file', 'slider', 'dimensions', 'integer-input'].includes(this.props.prompt.type) ||
          this.props.prompt.options ? null : (
            <div
              className={`text-xs absolute z-30 right-2 select-none pointer-events-none transition-all top bg-gray-700 px-3 rounded-md ${
                this.isMinLength ? 'text-red-500' : 'text-blue-500'
              } ${!this.isLongEnoughToDrop && this.props.size ? '' : ''}`}
            >
              {this.currentNumberOfCharacters} chars
            </div>
          )}

          <label
            htmlFor={this.props.prompt.attr}
            className="relative transition text-gray-600 focus-within:text-gray-800 block"
          >
            {Icon ? (
              <Icon
                className={`
					${this.props.size ? '' : 'h-6 w-6 top-5 left-2'} 
					${this.props.size === 'sm' && 'h-4 w-4 top-5 left-2'} 
					${this.props.size === 'lg' && 'h-8 w-8 top-5 left-2'} 
					transition absolute  select-none pointer-events-none opacity-75`}
              />
            ) : null}
            {this.props.prompt.type ? null : (
              <input
                name={this.props.prompt.attr}
                id={this.props.prompt.attr}
                maxLength={this.props.prompt.maxLength || 80}
                {...this.props.prompt}
                autoFocus={this.props.index === 0}
                className={`e-none ${
                  this.props.size || 'text-lg'
                } bg-white rounded-md px-4 py-2 w-full border  focus:border-gray-400 ${
                  this.props.prompt.error ? 'border-red-400' : 'border-gray-300'
                } font-regular mt-2 transition-all ${
                  Icon &&
                  `
						${this.props.size ? '' : 'pl-9'}
						${this.props.size === 'sm' && 'pl-7'}
						${this.props.size === 'lg' && 'pl-12'}
						`
                } `}
                onChange={this.onChange}
                onKeyDown={(e) => e.key === 'Enter' && onEnter()}
              />
            )}

            {this.props.prompt.type === 'text' && (
              <input
                name={this.props.prompt.attr}
                id={this.props.prompt.attr}
                maxLength={this.props.prompt.maxLength || 400}
                {...this.props.prompt}
                autoFocus={this.props.index === 0}
                className={`outline-none focus:outline-none ${
                  this.props.size || 'text-lg'
                } bg-blue-100 rounded-md px-4 py-2 w-full border  focus:border-gray-400  ${
                  this.props.prompt.error ? 'border-red-400' : 'border-gray-300'
                } font-regular mt-2 transition-all ${
                  Icon &&
                  `
						${this.props.size ? '' : 'pl-7'}
						${this.props.size === 'sm' && 'pl-7'}
						${this.props.size === 'lg' && 'pl-12'}
						`
                }`}
                onChange={this.onChange}
                onKeyDown={(e) => e.key === 'Enter' && onEnter()}
              />
            )}
          </label>

          {/* {this.props.prompt.type === 'dropdown' && (
            <EntryDropdown
              onChange={this.onChangeDropdown}
              options={this.props.prompt.options}
            />
          )} */}

          {this.props.prompt.type === 'code' && (
            <div className="mt-4 -mx-6" style={{ backgroundColor: '#f5f5f5' }}>
              <CodeEditor
                // height="350px"
                style={{
                  fontFamily: 'JetBrains Mono',
                  fontSize: '1rem',
                }}
                padding={30}
                language={this.props.language}
                onChange={this.onChange}
                autoFocus={this.props.index === 0}
                name={this.props.prompt.attr}
                id={this.props.prompt.attr}
                {...this.props.prompt}
              />
            </div>
          )}

          {this.props.prompt.type === 'textarea' && (
            <TextareaAutosize
              autoFocus={this.props.index === 0}
              minRows={this.props.minRows || 2}
              maxRows={10}
              name={this.props.prompt.attr}
              id={this.props.prompt.attr}
              {...this.props.prompt}
              className={`outline-none focus:outline-none ${
                this.props.size || 'text-lg'
              } bg-gray-700 rounded-xl ${
                this.props.size ? 'px-2 py-2' : 'px-4 py-4'
              }  min-w-full border border-blue-500 font-regular focus:border-blue-500 ${
                this.props.prompt.error ? 'border-red-400' : 'border-blue-500'
              } font-regular mt-2 text-blue-500`}
              onChange={this.onChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  onEnter();
                }
              }}
            />
          )}

          {this.props.prompt.type === 'select' && (
            <div className="inline-block relative w-full mt-2">
              <select
                className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded leading-tight focus:outline-none"
                value={this.props.prompt.value}
                onChange={this.onChange}
              >
                {this.props.prompt.selectOptions.map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          )}

          {this.props.prompt.type === 'slider' && (
            <div>
              <Slider
                value={this.props.prompt.value}
                min={this.props.prompt.sliderMin}
                max={this.props.prompt.sliderMax}
                step={this.props.prompt.sliderStep}
                onChange={(v) => {
                  this.props.prompt.value = v;
                }}
              />
            </div>
          )}

          {this.props.prompt.type === 'dimensions' && (
            <DimensionsInput
              attr={this.props.prompt.attr}
              value={{
                width: this.props.prompt.value.width,
                height: this.props.prompt.value.height,
              }}
              error={this.props.prompt.error}
              totalMin={this.props.prompt.totalMin}
              totalMax={this.props.prompt.totalMax}
              onChange={(fieldKey, val) => {
                this.props.prompt.error = '';
                this.props.prompt.value[fieldKey] = val
                console.log('this.props.prompt.value[fieldKey]', this.props.prompt.value[fieldKey])
              }}
            />
          )}

          {this.props.prompt.type === 'integer-input' && (
              <input
                name={this.props.prompt.attr}
                id={this.props.prompt.attr}
                value={this.props.prompt.value ?? ''}
                className={`outline-none focus:outline-none
                bg-white rounded-md px-4 py-2 w-full border  focus:border-gray-400
                ${this.props.prompt.error ? 'border-red-400' : 'border-gray-300'}
                font-regular mt-2 transition-all`}
                onChange={this.onIntegerChange}
                onKeyDown={(e) => e.key === 'Enter' && onEnter()}
              />

          )}

          {this.props.prompt.type === 'file' && (
            <FileUpload
              value={this.props.prompt.value}
              onChange={this.onFileChange}
              error={this.props.prompt.error}
              accept={this.props.prompt.fileAccept}
              isFetching={this.props.prompt.isFetching}
            />
          )}

          {this.props.prompt.type === 'radio' && this.props.prompt.options && (
            <>
              <div className="mt-1">
                {this.props.prompt.options.map((option, index) => (
                  <Option
                    key={index}
                    Icon={option.Icon}
                    title={option.title}
                    desc={option.desc}
                    active={option.value === this.props.prompt.value}
                    onClick={() => (this.props.prompt.value = option.value)}
                  />
                ))}
              </div>
            </>
          )}
        </div>

        {(this.props.prompt.label || this.props.prompt.error) && (
          <div
            htmlFor={this.props.prompt.attr}
            className={`${
              this.props.prompt.error ? 'text-red-400' : 'text-gray-400'
            } text-xs transition-all line mt-1`}
          >
            {parseLineBreaks(this.props.prompt.error) || this.props.prompt.label}
          </div>
        )}
      </div>
    );
  }
}

const Option = ({ index, title, desc, active, onClick, Icon }) => (
  <div
    className={`select-none flex w-full text-left items-center font-medium py-1 px-2 ${
      active ? 'hover:bg-emerald-200' : 'hover:bg-gray-200'
    } ${active ? 'bg-emerald-100' : ''} rounded-md cursor-pointer transition`}
    onClick={onClick}
  >
    <div
      className={`flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full ${
        active ? 'bg-emerald-500' : 'bg-gray-300'
      } mr-4 transition`}
    >
      {Icon ? (
        <Icon
          className={`transition h-4 w-4 ${
            active ? 'text-white' : 'text-gray-400'
          }`}
          aria-hidden="true"
        />
      ) : (
        <CheckIcon
          className={`transition h-4 w-4 ${
            active ? 'text-white' : 'text-gray-400'
          }`}
          aria-hidden="true"
        />
      )}
    </div>
    <div>
      {title}
      <div className="font-normal text-sm">{desc}</div>
    </div>
  </div>
);

export default EntryInput;

// export const MultipleOption = observer(({ title, value, onChange, placeholder, examples, index, ...props }) => {
// 	return (
// 	   <div className="mt-6">
// 			<label htmlFor={title} className="text-gray-600 font-medium text-md">{title}</label>
// 			{props.type === "textarea" ? <textarea
// 			  name={title}
// 			  id={title}
// 			  {...props}
// 			  className="focus:outline-none text-lg bg-white rounded-md px-4 py-2  min-w-full border border-gray-300 font-regular mt-2"
// 			  value={value}
// 			  onChange={e=>onChange(e.target.value,index)}
// 			  placeholder={placeholder} /> : <input
// 			  name={title}
// 			  id={title}
// 			  {...props}
// 			  className="focus:outline-none text-lg bg-white rounded-md px-4 py-2  min-w-full border border-gray-300 font-regular mt-2"
// 			  value={value}
// 			  onChange={e=>onChange(e.target.value,index)}
// 			  placeholder={placeholder}  />}
// 			{examples && <label htmlFor={title} className="text-gray-400 text-xs">{examples}</label>}
// 		  </div>
// 	  )
//   })
