import { Fragment } from 'react';
import mapRegex from './mapRegex';

export const parseTextBlock = (output) => {
  const pattern = /(`{1,2}).+?\1/gs;

  return mapRegex(pattern, output, (item) => {
    return item.isMatched ? (
      <b key={item.idx}>{item.value}</b>
    ) : (
      <Fragment key={item.idx}>{item.value}</Fragment>
    );
  });
};

export default parseTextBlock;
