const obj = {
  title: 'Recipe Maker',
  desc: 'Make a recipe from a list of ingredients.',
  category: 'Personal',
  // tags: [],
  permissions: ['user'],

  to: '/ai/recipe',
  api: '/ai/recipe',

  output: {
    title: 'Recipe',
    desc: 'Best outcome.',
    Icon: false,
  },

  prompts: [
    {
      title: 'Entry Text',
      desc: 'Input ingredients.',
      // n: 1,
      prompts: [
        {
          title: 'Content',
          attr: 'content',
          value: '',
          placeholder: 'Pancakes peppers onions.',
          label: '',
          type: 'textarea',
          maxLength: 600,
          // max: 100,
          min: 3,
          required: true,
          error: '',
          example: 'Pancakes peppers onions.',
        },
      ],
      example: {
        output:
          '1. Preheat a griddle or large frying pan over medium heat, 2. In a bowl, combine 1 cup of pancake mix with 1/4 cup of water. ,3. Add in 1/4 cup of diced peppers and onions. Stir until everything is combined. , 4. Scoop 1/4 cup batter onto the griddle or pan for each pancake. Cook until bubbles appear on the surface and the bottoms are golden brown, about 2 minutes per side. Serve warm!',
      },
    },
  ],
};

export default obj;
