const imageStandardVariation = {
  type: 'image/variation',

  title: 'Image Variation',
  desc: 'Generate similar images to the one you provide',
  category: 'Image',

  permissions: ['user'],

  to: '/ai/image/variation/standard',
  api: '/image-ai/variation/standard',

  formatPrompt: (prompt) => `Transform This image into ${prompt}`,

  output: {
    title: 'Completed',
    desc: 'Output.',
    Icon: false,
  },

  prompts: [
    {
      title: 'Image Variation',
      desc: 'Can take up to 10+ seconds.',
      prompts: [
        {
          type: 'textarea',
          title: 'Prompt',
          attr: 'prompt',
          value: '',
          example: 'Change this image into a brain made of liquid mercury.',
          placeholder: 'Change this image into a brain made of liquid mercury.',
          label: '',
          maxLength: 600,
          min: 3,
          required: true,
          error: '',
        },
        {
          type: 'file',
          title: 'Original Image',
          attr: 'originalImage',
          value: null,
          label: '',
          required: true,
          error: '',
          example: '/images/image-variation-input.png',
          fileAccept: 'image/png',
        },
        // {
         //  type: 'slider',
         //  title: 'steps',
         //  attr: 'steps',
         //  sliderMin: 1,
         //  sliderMax: 60,
         //  sliderStep: 1,
         //  value: 50,
         //  example: 50,
          // label: '',
          // required: true,
          // error: '',
         //},
        {
          hidden: true,
          attr: 'samples',
          value: 1,
        },
      ],
      example: {
        outputImages: ['/images/image-variation-output.png'],
      },
    },
  ],
};

export default imageStandardVariation;
