import React from 'react';
import CheckIcon from '../media/CheckIcon';

export const EntryPromptOptions = ({ prompts, currentPrompt, onChange }) => {
  if (prompts.length === 1) {
    return null;
  }
  return (
    <>
      <div className="select-none align-bottom text-left transform transition-all sm:align-middle transition  flex divide-x divide-gray-300 -mb-1">
        {prompts.map((prompt, index) => (
          <div
            key={index}
            className={`shadow-md hover:relative hover:shadow-2xl  transition py-4 px-4 pr-4 rounded-t-xl inline-flex ${
              currentPrompt === index
                ? 'text-blue-500'
                : 'text-blue-500 shadow-3xl'
            } font-medium border-b-2 ${
              currentPrompt === index ? 'border-gray-700' : 'border-gray-700'
            } ${currentPrompt === index ? 'bg-gray-700' : 'bg-gray-700'} ${
              currentPrompt === index ? 'hover:bg-gray-600' : 'hover:bg-gray-600'
            } cursor-pointer`}
            onClick={() => onChange(index)}
          >
            <div
              className={`transition mr-4  flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full ${
                currentPrompt === index ? 'bg-purple-400' : 'bg-purple-400'
              } `}
            >
              <CheckIcon
                className={`transition h-4 w-4 ${
                  currentPrompt === index ? 'text-blue-500' : 'text-blue-500'
                }`}
                aria-hidden="true"
              />
            </div>
            {prompt.title}
          </div>
        ))}
      </div>
    </>
  );
};

export default EntryPromptOptions;
