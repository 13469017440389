import { ArrowDownTrayIcon as DownloadIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';

const DownloadableImage = ({ src, filename, ...restProps }) => {
  const [completed, setCompleted] = useState(false);

  return (
    <div className="flex justify-center">
      <div className="relative">
        <img src={src} {...restProps} onLoad={() => setCompleted(true)} />

        {completed && (
          <a
            href={src}
            download={filename}
            id="prompt-submit-button"
            style={{ fontSize: 14 }}
            className={
              'absolute bottom-3 right-3 select-none py-1 px-4 bg-gray-800 hover:bg-gray-700 ' +
              'hover:via-blue-700 hover:to-gray-700 text-blue-500 rounded-xl flex md:inline-flex items-center ' +
              'font-medium text-lg cursor-pointer mt-4 md:mx-0 transition'
            }
          >
            <DownloadIcon className="w-4 h-4 mr-1" />
            Download
          </a>
        )}
      </div>
    </div>
  );
};

export default DownloadableImage;
