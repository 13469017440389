const chatStandard = {
  title: 'Chat-Ai',
  desc: 'Ask me to do tasks or answer questions.',
  category: 'Chat',

  permissions: ['user'],

  to: '/chat/standard',
  api: '/chat-ai/standard',
};

export default chatStandard;
