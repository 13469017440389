const imageStandardEdit = {
  type: 'image/edit',

  title: 'Image Edit',
  desc: 'Edit the original image by the providing mask and description',
  category: '',

  permissions: ['user'],

  to: '/ai/image/edit/standard',
  api: '/image-ai/edit/standard',

  formatPrompt: (prompt) => `${prompt}`,

  output: {
    title: 'Completed',
    desc: 'Can take up to 10+ seconds.',
    Icon: false,
  },

  prompts: [
    {
      title: 'Image Edit',
      desc: 'Input what you would like to see.',
      prompts: [
        {
          type: 'file',
          title: 'Original Image',
          attr: 'originalImage',
          value: null,
          example: '/images/image-edit-original-input.png',
          label: '',
          required: true,
          error: '',
        },
        {
          type: 'file',
          title: 'Mask Image',
          attr: 'maskImage',
          value: null,
          example: '/images/image-edit-mask-input.png',
          label: '',
          required: true,
          error: '',
        },
        {
          type: 'textarea',
          title: 'Description',
          attr: 'prompt',
          value: '',
          example: 'spectral, psychedelic, masterpiece,',
          placeholder: 'spectral, psychedelic, masterpiece,',
          label: '',
          maxLength: 600,
          // max: 100,
          min: 3,
          required: true,
          error: '',
        },
        // {
        //   type: 'slider',
        //   title: 'Steps',
        //   attr: 'steps',
        //   sliderMin: 50,
        //   sliderMax: 80,
        //   sliderStep: 1,
        //   value: 50,
        //   example: 50,
        //   label: '',
        //   required: true,
        //   error: '',
        // },
        {
          hidden: true,
          attr: 'samples',
          value: 1,
        },
      ],
      example: {
        outputImages: ['/images/image-edit-output.png'],
      },
    },
  ],
};

export default imageStandardEdit;
